import { sleep, Emptyable } from "@simplyk/common";

export const runWithMinimumDuration = async <T>(
  callback: () => Promise<Emptyable<{ shouldAwaitMinimumDuration: boolean; callbackResult: T }>>,
  minimumTimeToContinue: number
): Promise<Emptyable<T>> => {
  const startDate = new Date();

  const callbackData = await callback();

  const endDate = new Date();
  const duration = endDate.getTime() - startDate.getTime();

  if (minimumTimeToContinue > 0 && duration < minimumTimeToContinue && callbackData?.shouldAwaitMinimumDuration) {
    await sleep(minimumTimeToContinue - duration);
  }

  return callbackData?.callbackResult;
};
