import { stringify } from "querystring";

import {
  FormType,
  FrontendRoutes,
  DonationFormCategory,
  TicketingFormCategory,
  IsoLocale,
  getDonationOrTicketingFormLink,
  FormCategory,
  Emptyable,
} from "@simplyk/common";
import { inferRouterOutputs } from "@trpc/server";

import { Event } from "../components/PaymentProcessor/type";
import { ThankYouPageQueryParams } from "../components/ThankYouPage/type";
import { OrganizationObject } from "../gql/gql-types";

import { AppRouterType } from "./trpc";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];

export interface ThankYouUrlParams {
  command: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];
  formType: FormType;
  organization: OrganizationObject;
  postTransactionUrl: string | null | undefined;
  isEmbeddedModal: boolean;
  generateETicket: boolean;
  event?: Event;
  formData: DonationFormOutput | TicketingOutput;
  isoLocale: IsoLocale;
  category: DonationFormCategory | TicketingFormCategory;
  isAuction: boolean;
  themeColor?: Emptyable<string>;
}

export const getThankYouPageUrl = ({
  command,
  formType,
  organization,
  postTransactionUrl,
  isEmbeddedModal,
  generateETicket,
  event,
  formData,
  isoLocale,
  category,
  themeColor,
}: ThankYouUrlParams): string => {
  const params: ThankYouPageQueryParams & Record<string, string | null | undefined | number> = {
    organizationName: organization?.name || "",
    logoUrl: formData.logoUrl || organization?.logoUrl || null,
    amount: command.totalAmount - command.tipAmount,
    tipAmount: command.tipAmount,
    formCategory: category || null,
    modal: isEmbeddedModal ? "true" : "false",
    email: command.email || null,
    formType,
    shareLink:
      process.env.NEXT_PUBLIC_FRONTEND_URL +
      getDonationOrTicketingFormLink({
        form: {
          formCategory: category as FormCategory,
          formType,
          id: formData.id,
          path: formData.path,
          isPrimaryCampaign: (formData as DonationFormOutput).isPrimaryCampaign || false,
          isPrimaryTeam: (formData as DonationFormOutput).isPrimaryTeam || false,
        },
        isoLocale,
      }),
    generateTaxReceipt: getGenerateTaxReceipt(formType, formData, organization, command) ? "true" : "false",
    generateETicket: generateETicket && (command.productsAmount || 0) > 0 ? "true" : "false",
    resourceEventEndTime: event?.endTime || null,
    themeColor,
  };

  return postTransactionUrl || `/${isoLocale}${FrontendRoutes.ThankYou}?${stringify(params)}`;
};

const getGenerateTaxReceipt = (
  formType: FormType,
  formData: DonationFormOutput | TicketingOutput,
  organization: OrganizationObject,
  command: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"]
) => {
  if (formType === FormType.DonationForm && !(formData as DonationFormOutput).hasReceipt) {
    return false;
  }

  return (command.eligibleAmount || 0) > 0 && organization.canGenerateReceipt;
};
