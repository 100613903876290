import { ParsedUrlQuery } from "querystring";

import { useCallback, useEffect, useMemo } from "react";

import { FormQueryParams } from "@simplyk/common";

import { useAmplitude } from "./amplitude/useAmplitude";

import { AmplitudeEvents } from "@/constants/amplitude";
import { POST_MESSAGE_ID } from "@/constants/EmbedForm";

interface OpenPopupEventMessage {
  id: string;
  open: boolean;
}

export const useIsEmbeddedModal = (query: ParsedUrlQuery) =>
  useMemo(() => query?.[FormQueryParams.Modal] === "true", [query]);

export const useEmbeddedModal = (isEmbeddedModal: boolean) => {
  const { logAmplitudeEvent } = useAmplitude();
  const postMessageClose = useCallback(() => {
    parent.postMessage(
      {
        id: POST_MESSAGE_ID,
        close: true,
      },
      "*"
    );
  }, []);

  useEffect(() => {
    if (isEmbeddedModal) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          postMessageClose();
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isEmbeddedModal, postMessageClose]);

  useEffect(() => {
    if (isEmbeddedModal) {
      const logEmbedOpenOnAmplitude = (event: MessageEvent<OpenPopupEventMessage>) => {
        if (!event.isTrusted) {
          return;
        }
        if (event.data && event.data.id === POST_MESSAGE_ID && event.data.open) {
          logAmplitudeEvent(AmplitudeEvents.EmbedPopupClick);
        }
      };

      window.addEventListener("message", logEmbedOpenOnAmplitude);
      return () => {
        window.removeEventListener("message", logEmbedOpenOnAmplitude);
      };
    }
  }, [isEmbeddedModal, logAmplitudeEvent]);

  return { postMessageClose };
};
