/* eslint-disable sonarjs/no-duplicate-string */

import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  AmplitudeFlagKey,
  FormType,
  convertCountryCodeToCountry,
  Emptyable,
  CountryCode,
  PaymentMethod,
} from "@simplyk/common";
import { useElements } from "@stripe/react-stripe-js";
import { inferRouterOutputs } from "@trpc/server";
import { useSearchParams } from "next/navigation";
import Router, { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useReOpenCommandMutation } from "../../gql/queries/generated/commandQuery";
import { captureSentryMessage } from "../../helpers/sentry";
import { getThankYouPageUrl } from "../../helpers/thankYouPage";
import { runWithMinimumDuration } from "../../helpers/time";
import { AppRouterType } from "../../helpers/trpc";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useCreateRegistrationCampaigns } from "../../hooks/useCreateRegistrationCampaigns";
import { useIsEmbeddedModal } from "../../hooks/useEmbeddedModal";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { getAnimationNumber, TIME_BETWEEN_STEPS } from "../Animations/ThankYouLoader/ThankYouLoader";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";

import { HandleErrorParams, unknownPaymentErrorCode } from "./helper";
import { usePaymentErrorContext } from "./PaymentErrorContext";
import { useAmplitudePaymentEventLog } from "./useAmplitudePaymentEventLog";
import { useCashapp } from "./useCashapp";
import { useOfflinePayment } from "./useOfflinePayment";
import { usePad } from "./usePad";
import { useStripePayment } from "./useStripePayment";

import { useDurationOnPage } from "@/hooks/useDurationOnPage";
import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { useLocaleContext } from "@/src/contexts/LocaleContext";
import { SessionContext } from "@/src/contexts/SessionContext";
import { CommandSource, DiscountObject, OrganizationObject } from "@/src/gql/gql-types";
import { TicketingPaymentInput } from "@/types/ticketing";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];

export type Props<T extends FieldValues> = {
  handleSubmit: UseFormHandleSubmit<TicketingPaymentInput | DonationFormPaymentInput>;
  formObject: DonationFormOutput | TicketingOutput;
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  trigger: UseFormTrigger<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  validDiscount?: Emptyable<DiscountObject>;
};

export function usePaymentSubmit<T extends FieldValues>({
  handleSubmit,
  formObject,
  validDiscount,
  setValue,
  errors,
  control,
}: Props<T>) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const searchParams = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());

  const emailField = "email" as Path<T>;
  const donorEmail = useWatch({ control, name: emailField });

  const { event: ticketingEvent } = useContext(FrontendTicketingContext);
  const { sessionId } = useContext(SessionContext);

  const {
    commandId,
    organization,
    formType,
    isArchived,
    tip,
    selectedPaymentMethod,
    isEmbed,
    category,
    formData,
    postTransactionUrl,
    prioritizeBankPayment,
    showProceedButton,
    isSubmitting,
    setIsSubmitting,
    paymentMode,
    selectedTip,
    shouldSendTipPercentage,
    isAuction,
    generateETicket,
    themeColor,
  } = useContext(FrontendFormContext);
  const router = useRouter();
  const isEmbeddedModal = useIsEmbeddedModal(router.query);
  const { locale, isoLocale } = useLocaleContext();
  const { logAmplitudeEvent } = useAmplitude();
  const { getDurationTimeOnPage } = useDurationOnPage();
  const { shouldCreateCampaigns, createCampaigns } = useCreateRegistrationCampaigns();
  const elements = useElements();
  const { logDonorFormSubmitted, logFormClickSubmitPaymentError } = useAmplitudePaymentEventLog({
    errors,
    formObject,
  });
  const [reOpenCommandMutation] = useReOpenCommandMutation();

  useEffect(() => {
    if (prioritizeBankPayment) {
      logAmplitudeEvent(AmplitudeEvents.FormInsertAmountZeffyModelMessage, {
        abTestMessage: "positive",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prioritizeBankPayment]);

  const getFormErrors = useCallback(async (): Promise<string | null> => {
    if (isArchived) {
      return t("common", "payment.coverFees.coverFees");
    }

    if (organization?.blockPayment) {
      return t("common", "payment_blocked");
    }

    if (!organization.isStripeCustomAccountActive) {
      return t("common", "bankIsNotConnectedError");
    }

    return null;
  }, [isArchived, organization?.blockPayment, organization.isStripeCustomAccountActive, t]);

  const getThankYouUrl = useCallback(
    ({
      command,
      formType,
      organization,
    }: {
      command?: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];
      formType: FormType;
      organization: OrganizationObject;
    }): string => {
      return getThankYouPageUrl({
        command: command as NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"],
        formType,
        organization,
        postTransactionUrl,
        isEmbeddedModal,
        generateETicket,
        event: ticketingEvent,
        formData,
        isoLocale,
        category,
        isAuction,
        themeColor,
      });
    },
    [
      category,
      formData,
      generateETicket,
      isAuction,
      isEmbeddedModal,
      isoLocale,
      postTransactionUrl,
      themeColor,
      ticketingEvent,
    ]
  );

  const { setPaymentError } = usePaymentErrorContext();
  const handlePaymentError = useCallback(
    ({ message, commandId, metadata, isPaymentSucceeded }: HandleErrorParams) => {
      logDonorFormSubmitted({
        isExpressCheckout: metadata?.isExpressCheckout as boolean,
        hasSucceeded: Boolean(isPaymentSucceeded),
        formErrors: [(metadata?.code as string) || "unknownError"],
      });
      logFormClickSubmitPaymentError(metadata);

      if (isPaymentSucceeded) {
        enqueueSnackbar(t("common", "paymentSucceededWithErrorsMessage") + " " + message, {
          vibe: "positive",
          persist: true,
        });
      } else {
        setPaymentError(message || t("common", "unknown_error", { code: unknownPaymentErrorCode.other1.code }));
      }

      if (commandId && !isPaymentSucceeded) {
        reOpenCommandMutation({ variables: { commandId } });
      }

      setIsSubmitting(false);
    },
    [
      enqueueSnackbar,
      logDonorFormSubmitted,
      logFormClickSubmitPaymentError,
      reOpenCommandMutation,
      setIsSubmitting,
      setPaymentError,
      t,
    ]
  );

  const handlePostSubmit = useCallback(
    async ({
      paymentInput,
      command,
      formType,
      isExpressCheckout,
    }: {
      paymentInput: TicketingPaymentInput | DonationFormPaymentInput;
      command?: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];
      formType: FormType;
      isExpressCheckout?: boolean;
    }): Promise<{ returnUrl?: string | null; error?: string | null }> => {
      logDonorFormSubmitted({
        isExpressCheckout: Boolean(isExpressCheckout),
        hasSucceeded: true,
      });
      if (shouldCreateCampaigns(paymentInput, formType)) {
        const result = await createCampaigns({
          firstName: command?.firstName,
          lastName: command?.lastName,
          email: command?.email,
          paymentInput,
        });
        if (result.returnUrl) {
          return { returnUrl: result.returnUrl };
        }
        return { error: result.error };
      }
      const thankYouUrl = getThankYouUrl({ command, formType, organization });

      const returnUrl = postTransactionUrl || thankYouUrl;

      return { returnUrl };
    },
    [createCampaigns, getThankYouUrl, logDonorFormSubmitted, organization, postTransactionUrl, shouldCreateCampaigns]
  );

  const { payByPad } = usePad({ setValue, handleError: handlePaymentError, handlePostSubmit });
  const { payByCashapp } = useCashapp({ setValue, handleError: handlePaymentError, getThankYouUrl });
  const { payOffline } = useOfflinePayment({ setValue, handleError: handlePaymentError, handlePostSubmit });
  const { payByStripe } = useStripePayment({ setValue, handleError: handlePaymentError, handlePostSubmit });

  const onInvalid = useCallback(
    async (isExpressCheckout: boolean) => {
      const addressElement = elements?.getElement?.("address");
      await addressElement?.getValue?.();
      await elements?.submit();
      logDonorFormSubmitted({
        isExpressCheckout,
        hasSucceeded: false,
      });
    },
    [elements, logDonorFormSubmitted]
  );

  const { getExperimentValue } = useAmplitude();
  const [taxDeductionMessageAbcTest, setTaxDeductionMessageAbcTest] = useState<string | undefined>(undefined);
  useEffect(() => {
    const getFlag = async () => {
      const flag = await getExperimentValue({ flagKey: AmplitudeFlagKey.TaxDeductionMessaging });
      setTaxDeductionMessageAbcTest(flag?.value);
    };
    getFlag();
  }, [getExperimentValue]);

  const handleFormSubmit = useCallback(
    (isExpressCheckout = false) =>
      async (event?: React.SyntheticEvent) => {
        const sentryMessagePayload = {
          isExpressCheckout: isExpressCheckout.toString(),
          commandId,
          sessionId,
          paymentMethod: selectedPaymentMethod ? selectedPaymentMethod.toString() : "null",
          formType,
          organizationId: organization?.id,
          tip: tip.toString(),
          isEmbed: isEmbed.toString(),
          category: category ? category.toString() : "null",
          showProceedButton: showProceedButton.toString(),
          isSubmitting: isSubmitting.toString(),
          email: donorEmail,
        };
        captureSentryMessage({
          message: "Start handle form submit",
          params: sentryMessagePayload,
        });
        event && event.preventDefault();

        handleSubmit(
          async (paymentInput) => {
            if (selectedPaymentMethod !== PaymentMethod.Pad) {
              setIsSubmitting(true);
            }

            const minimumDuration =
              selectedPaymentMethod !== PaymentMethod.CashApp
                ? TIME_BETWEEN_STEPS * 1000 * getAnimationNumber({ isFree: false })
                : 0;
            const url = await runWithMinimumDuration<Emptyable<string>>(async () => {
              captureSentryMessage({
                message: "Get errors",
                params: sentryMessagePayload,
              });
              const formError = await getFormErrors();
              if (formError) {
                handlePaymentError({ message: formError });
                return { callbackResult: null, shouldAwaitMinimumDuration: false };
              }

              captureSentryMessage({
                message: "Get address",
                params: sentryMessagePayload,
              });
              const addressElement = elements?.getElement?.("address");
              const addressValue = await addressElement?.getValue?.();
              if (addressValue) {
                const { city, country, line1, line2, postal_code, state } = addressValue.value.address;
                paymentInput.address = line2 ? `${line1} ${line2}` : line1;
                paymentInput.city = city;
                paymentInput.postalCode = postal_code;
                paymentInput.region = state;
                paymentInput.country = convertCountryCodeToCountry(country as CountryCode);
                paymentInput.firstName = addressValue.value.firstName;
                paymentInput.lastName = addressValue.value.lastName;
              }

              if ((tip && tip < 0) || !organization?.country || !formType) {
                handlePaymentError({
                  message: t("common", "unknown_error", { code: unknownPaymentErrorCode.other2.code }),
                });
                return { callbackResult: null, shouldAwaitMinimumDuration: false };
              }

              const submitCommandInput: SubmitCommandFormInput = {
                paymentInput,
                paymentMethod: selectedPaymentMethod,
                source: CommandSource.FormSubmission,
                formObject,
                user: null,
                formType,
                validDiscount: validDiscount || null,
                session: { sessionId, durationOnPage: getDurationTimeOnPage() },
                routerQuery: queryParams,
                commandId,
                locale,
                tip: (!shouldSendTipPercentage ? tip : 0) || 0,
                tipPercentage: (shouldSendTipPercentage ? selectedTip.percentage : 0) || 0,
                stripePaymentMethodId: null,
                isExpressCheckout,
                taxDeductionMessageAbcTest,
              };

              captureSentryMessage({
                message: "Get payment method",
                params: sentryMessagePayload,
              });

              if (selectedPaymentMethod === PaymentMethod.Pad) {
                return {
                  callbackResult: await payByPad(submitCommandInput, paymentMode),
                  shouldAwaitMinimumDuration: false,
                };
              }
              if (selectedPaymentMethod === PaymentMethod.CashApp) {
                payByCashapp(submitCommandInput, paymentMode);
                return { callbackResult: null, shouldAwaitMinimumDuration: false };
              }

              if (selectedPaymentMethod === PaymentMethod.Cheque || selectedPaymentMethod === PaymentMethod.Cash) {
                return { callbackResult: await payOffline(submitCommandInput), shouldAwaitMinimumDuration: true };
              }

              return { callbackResult: await payByStripe(submitCommandInput), shouldAwaitMinimumDuration: true };
            }, minimumDuration);
            if (url) {
              await Router.push(url);
            }
          },
          () => onInvalid(isExpressCheckout)
        )().finally(() => {
          setIsSubmitting(false);
        });
      },
    [
      commandId,
      sessionId,
      selectedPaymentMethod,
      formType,
      organization?.id,
      organization?.country,
      tip,
      isEmbed,
      category,
      showProceedButton,
      isSubmitting,
      donorEmail,
      handleSubmit,
      setIsSubmitting,
      getFormErrors,
      elements,
      formObject,
      validDiscount,
      getDurationTimeOnPage,
      queryParams,
      locale,
      shouldSendTipPercentage,
      selectedTip.percentage,
      taxDeductionMessageAbcTest,
      payByStripe,
      handlePaymentError,
      t,
      payByPad,
      paymentMode,
      payByCashapp,
      payOffline,
      onInvalid,
    ]
  );

  return {
    handleFormSubmit,
    handlePaymentError,
  };
}
