import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import { DiscountFragmentFragmentDoc } from './fragments';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDiscountQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetDiscountQuery = { __typename?: 'Query', getDiscount: { __typename?: 'DiscountResponseObject', object?: { __typename?: 'DiscountObject', id: string, body: string, amount: number, limit?: number | null, type: Types.DiscountType, remainingUses?: number | null, sortIndex: number, ticketingId: string } | null } };

export type GetDiscountCodeFromTicketingIdAndBodyMutationVariables = Types.Exact<{
  ticketingId: Types.Scalars['String'];
  discountCodeBody: Types.Scalars['String'];
  occurrenceId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetDiscountCodeFromTicketingIdAndBodyMutation = { __typename?: 'Mutation', getDiscountCodeFromTicketingIdAndBody: { __typename?: 'DiscountResponseObject', object?: { __typename?: 'DiscountObject', id: string, body: string, amount: number, limit?: number | null, type: Types.DiscountType, remainingUses?: number | null, sortIndex: number, ticketingId: string } | null } };


export const GetDiscountDocument = gql`
    query getDiscount($id: String!) {
  getDiscount(id: $id) {
    object {
      ...DiscountFragment
    }
  }
}
    ${DiscountFragmentFragmentDoc}`;

/**
 * __useGetDiscountQuery__
 *
 * To run a query within a React component, call `useGetDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDiscountQuery(baseOptions: Apollo.QueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, options);
      }
export function useGetDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountQuery, GetDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscountQuery, GetDiscountQueryVariables>(GetDiscountDocument, options);
        }
export type GetDiscountQueryHookResult = ReturnType<typeof useGetDiscountQuery>;
export type GetDiscountLazyQueryHookResult = ReturnType<typeof useGetDiscountLazyQuery>;
export type GetDiscountQueryResult = Apollo.QueryResult<GetDiscountQuery, GetDiscountQueryVariables>;
export const GetDiscountCodeFromTicketingIdAndBodyDocument = gql`
    mutation getDiscountCodeFromTicketingIdAndBody($ticketingId: String!, $discountCodeBody: String!, $occurrenceId: String) {
  getDiscountCodeFromTicketingIdAndBody(
    ticketingId: $ticketingId
    discountCodeBody: $discountCodeBody
    occurrenceId: $occurrenceId
  ) {
    object {
      ...DiscountFragment
    }
  }
}
    ${DiscountFragmentFragmentDoc}`;
export type GetDiscountCodeFromTicketingIdAndBodyMutationFn = Apollo.MutationFunction<GetDiscountCodeFromTicketingIdAndBodyMutation, GetDiscountCodeFromTicketingIdAndBodyMutationVariables>;

/**
 * __useGetDiscountCodeFromTicketingIdAndBodyMutation__
 *
 * To run a mutation, you first call `useGetDiscountCodeFromTicketingIdAndBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountCodeFromTicketingIdAndBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDiscountCodeFromTicketingIdAndBodyMutation, { data, loading, error }] = useGetDiscountCodeFromTicketingIdAndBodyMutation({
 *   variables: {
 *      ticketingId: // value for 'ticketingId'
 *      discountCodeBody: // value for 'discountCodeBody'
 *      occurrenceId: // value for 'occurrenceId'
 *   },
 * });
 */
export function useGetDiscountCodeFromTicketingIdAndBodyMutation(baseOptions?: Apollo.MutationHookOptions<GetDiscountCodeFromTicketingIdAndBodyMutation, GetDiscountCodeFromTicketingIdAndBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDiscountCodeFromTicketingIdAndBodyMutation, GetDiscountCodeFromTicketingIdAndBodyMutationVariables>(GetDiscountCodeFromTicketingIdAndBodyDocument, options);
      }
export type GetDiscountCodeFromTicketingIdAndBodyMutationHookResult = ReturnType<typeof useGetDiscountCodeFromTicketingIdAndBodyMutation>;
export type GetDiscountCodeFromTicketingIdAndBodyMutationResult = Apollo.MutationResult<GetDiscountCodeFromTicketingIdAndBodyMutation>;
export type GetDiscountCodeFromTicketingIdAndBodyMutationOptions = Apollo.BaseMutationOptions<GetDiscountCodeFromTicketingIdAndBodyMutation, GetDiscountCodeFromTicketingIdAndBodyMutationVariables>;