import { useState, useEffect, FunctionComponent, PropsWithChildren, useContext, useMemo } from "react";

import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { AmplitudeFlagKey, StripePaymentMethod, Utils, OrganizationCountry, PaymentMethod } from "@simplyk/common";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

import { getBaseInputFocusedStyles } from "../components/design-system/BaseInput/styles";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { ExpressCheckoutWallet, StripeContext } from "../contexts/StripeContext";
import { listPaymentMethods } from "../helpers/payment-method";
import { useTranslate } from "../hooks/useTranslate";

import { serializeLocale } from "@/helpers/stripe";
import { useAmplitudeFeatureFlag } from "@/hooks/amplitude/useAmplitudeFeatureFlag";

interface StripeContextProviderProps {
  country?: OrganizationCountry | null;
}

const WAITING_TIME_BEFORE_DISPLAY_STRIPE_ERROR = 2000;

export const STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD: Record<PaymentMethodWithStripe, StripePaymentMethod> = {
  [PaymentMethod.Card]: "card",
  [PaymentMethod.Ach]: "us_bank_account",
  [PaymentMethod.CashApp]: "cashapp",
};

export type PaymentMethodWithStripe = PaymentMethod.Card | PaymentMethod.Ach | PaymentMethod.CashApp;

export const isPaymentMethodWithStripe = (pm: PaymentMethod): pm is PaymentMethodWithStripe => {
  return pm === PaymentMethod.Card || pm === PaymentMethod.Ach || pm === PaymentMethod.CashApp;
};

const StripeError = () => {
  const [displayError, setDisplayError] = useState(false);

  const { t } = useTranslate();

  useEffect(() => {
    const errorTimer: NodeJS.Timeout = setTimeout(
      () => setDisplayError(true),
      WAITING_TIME_BEFORE_DISPLAY_STRIPE_ERROR
    );
    return () => {
      clearTimeout(errorTimer);
    };
  }, []);

  if (!displayError) {
    return <></>;
  }
  return <ErrorMessage message={t("common", "unableToLoadStripe")} />;
};

export const getBaseStripeAppearance: (theme: Theme) => NonNullable<StripeElementsOptions["appearance"]> = (theme) => {
  const { isFormV2 } = theme.constants;

  return {
    rules: {
      ".Input": {
        ...(theme.typography.body2 as Record<string, string>),
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.border.form.quiet}`,
        boxShadow: "none",
        color: theme.palette.text.form.moderate,
        fill: theme.palette.text.form.moderate,
        backgroundColor: "white",
      },
      ".Input:hover": {
        borderColor: theme.palette.border.brand.intense,
      },
      ".Input:focus": getBaseInputFocusedStyles(theme) as Record<string, string>,
      ".Input:disabled": {
        background: theme.palette.surface.form.moderate,
        borderColor: theme.palette.border.form.moderate,
        color: theme.palette.text.form.moderate,
        fill: theme.palette.text.form.moderate,
        opacity: "0.4px",
      },
      ".Input--invalid": {
        boxShadow: "none",
        color: theme.palette.text.form.moderate,
        fill: theme.palette.text.form.moderate,
      },
      ".Input--invalid::placeholder": {
        color: theme.palette.text.form.moderate,
      },
      ".Label": theme.typography.body2 as Record<string, string>,
      ".Error": {
        ...(theme.typography.caption as Record<string, string>),
        color: theme.palette.text.danger.quiet,
        marginTop: theme.spacing(0.5),
        minHeight: theme.spacing(2),
      },
    },
    variables: {
      colorPrimary: isFormV2 ? theme.palette.text.form.moderate : theme.palette.text.brand.moderate,
      colorDanger: theme.palette.border.danger.intense,
      colorTextSecondary: theme.palette.text.form.moderate,
      colorText: theme.palette.text.form.moderate,
      borderRadius: theme.radius(1),
      colorBackground: isFormV2 ? theme.palette.surface.form.supershy : undefined,
      colorTextPlaceholder: isFormV2 ? theme.palette.text.form.quiet : undefined,
    },
  };
};

export const StripeProvider: FunctionComponent<PropsWithChildren<StripeContextProviderProps>> = ({ children }) => {
  const {
    organization,
    currency,
    displayedFormAmount,
    displayedFormAmountWithTip,
    stripeRecurrenceInterval,
    formData,
    formType,
    areBankPaymentMethodsAllowed,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    prioritizeBankPayment,
    displayExpressCheckout,
    expressCheckoutIsSelected,
    isExpressCheckoutLoading,
    paymentMode,
    isAuction,
    isPreviewTemplateMode,
  } = useContext(FrontendFormContext);

  const theme = useTheme();

  const { selectedOccurrenceWithRates } = useContext(FrontendTicketingContext);
  const { locale } = useLocaleContext();

  const stripeInstance = useMemo(() => {
    const stripePublicApiKey =
      organization.country === OrganizationCountry.Canada
        ? (process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_CA as string)
        : (process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_USA as string);

    return loadStripe(stripePublicApiKey, { locale: serializeLocale(locale) });
  }, [locale, organization.country]);

  const { isActive: isCashappEnabled } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.IsCashappEnabled,
    userProperties: { organizationId: organization.id, formId: formData.id },
  });

  const { isActive: allowStripeLink, loading: isStripeLinkFlagLoading } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.StripeLinkPaymentMethod,
    userProperties: { organizationId: organization.id, formId: formData.id },
  });

  const [expressCheckoutWallet, setExpressCheckoutWallet] = useState<ExpressCheckoutWallet | undefined>(undefined);

  const paymentMethods = useMemo(
    () =>
      listPaymentMethods({
        formData,
        stripeRecurrenceInterval,
        selectedOccurrence: selectedOccurrenceWithRates?.occurrence,
        organizationCountry: organization.country,
        totalAmount: displayedFormAmount,
        formType,
        areBankPaymentMethodsAllowed,
        orgCardMaximumAmount: organization.cardMaximumAmount || Infinity,
        isAuction,
        isStripeCustomAccountActive: organization.isStripeCustomAccountActive,
        isCashappEnabled: Boolean(isCashappEnabled),
        isPreviewTemplateMode,
      }),
    [
      displayedFormAmount,
      formData,
      formType,
      isAuction,
      isCashappEnabled,
      areBankPaymentMethodsAllowed,
      organization.cardMaximumAmount,
      organization.country,
      organization.isStripeCustomAccountActive,
      selectedOccurrenceWithRates?.occurrence,
      stripeRecurrenceInterval,
      isPreviewTemplateMode,
    ]
  );

  const stripePaymentMethodTypes: StripePaymentMethod[] = useMemo(
    () =>
      paymentMethods
        .map((pm) => {
          if (!isPaymentMethodWithStripe(pm)) {
            return;
          }
          return STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD[pm];
        })
        .filter(Utils.notEmpty),
    [paymentMethods]
  );

  const stripePaymentMethods: PaymentMethod[] = useMemo(
    () => paymentMethods.filter((pm) => isPaymentMethodWithStripe(pm)),
    [paymentMethods]
  );

  const zeffyPaymentMethods = useMemo(
    () =>
      paymentMethods
        .filter((pm) => !isPaymentMethodWithStripe(pm) && pm !== PaymentMethod.ApplePayOrGooglePay)
        .filter(Utils.notEmpty),
    [paymentMethods]
  );

  const isPadOrAch = selectedPaymentMethod === PaymentMethod.Ach || selectedPaymentMethod === PaymentMethod.Pad;
  const options: StripeElementsOptions = {
    mode: paymentMode,
    ...(paymentMode !== "setup" ? { amount: displayedFormAmountWithTip || 100 } : {}),
    currency,
    appearance: getBaseStripeAppearance(theme),
    paymentMethodTypes: stripePaymentMethodTypes,
    // setupFutureUsage: "off_session" as const,
    paymentMethodOptions: {
      us_bank_account: {
        financial_connections: { permissions: ["payment_method" as const] },
      },
    },
    paymentMethodCreation: "manual" as const,
    setupFutureUsage: isPadOrAch ? ("off_session" as const) : null,
  };

  const hasPad = paymentMethods.includes(PaymentMethod.Pad);
  const hasAch = paymentMethods.includes(PaymentMethod.Ach);

  // If the currently selected payment method is not available, we need to select another one
  useEffect(() => {
    // We ignore this logic when express checkout is supported and selected
    if (expressCheckoutIsSelected && displayExpressCheckout) {
      return;
    }
    if (
      selectedPaymentMethod &&
      !zeffyPaymentMethods.includes(selectedPaymentMethod) &&
      !stripePaymentMethods.includes(selectedPaymentMethod)
    ) {
      if (prioritizeBankPayment && (hasPad || hasAch)) {
        setSelectedPaymentMethod(hasPad ? PaymentMethod.Pad : PaymentMethod.Ach);
      } else if (stripePaymentMethods.length > 0) {
        setSelectedPaymentMethod(stripePaymentMethods[0]);
      } else if (zeffyPaymentMethods.length > 0) {
        setSelectedPaymentMethod(zeffyPaymentMethods[0]);
      } else {
        setSelectedPaymentMethod(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod, stripePaymentMethods, zeffyPaymentMethods, isExpressCheckoutLoading]);

  // Set card as default payment method if express checkout isn't supported or if in previewTemplateMode, no payment method is selected
  // and card is available
  useEffect(() => {
    if (
      !selectedPaymentMethod &&
      (!displayExpressCheckout || isPreviewTemplateMode) &&
      !isExpressCheckoutLoading &&
      stripePaymentMethods.includes(PaymentMethod.Card)
    ) {
      setSelectedPaymentMethod(PaymentMethod.Card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpressCheckoutLoading]);

  try {
    return (
      <StripeContext.Provider
        value={{
          hasPad,
          hasAch,
          stripePaymentMethodTypes,
          stripePaymentMethods,
          zeffyPaymentMethods,
          allowStripeLink: allowStripeLink ?? false,
          isStripeLinkFlagLoading,
          expressCheckoutWallet,
          setExpressCheckoutWallet,
        }}
      >
        <Elements key={locale} stripe={stripeInstance} options={options}>
          {children}
        </Elements>
      </StripeContext.Provider>
    );
  } catch (error) {
    return <StripeError />;
  }
};

export const StripeCustomAccountProvider = ({ children }: PropsWithChildren) => {
  const { organization } = useCurrentUserContext();
  const { locale } = useLocaleContext();

  const stripeInstance = useMemo(() => {
    const stripePublicApiKey =
      organization?.country === OrganizationCountry.Canada
        ? (process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_CA as string)
        : (process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_USA as string);

    return loadStripe(stripePublicApiKey, {
      locale: serializeLocale(locale),
      stripeAccount: organization?.stripeCustomAccountId || "",
    });
  }, [locale, organization?.country, organization?.stripeCustomAccountId]);

  try {
    return (
      <Elements key={locale} stripe={stripeInstance}>
        {children}
      </Elements>
    );
  } catch (error) {
    return <StripeError />;
  }
};
