import { createContext, useContext, useState, ReactNode } from "react";

interface PaymentErrorContextType {
  paymentError: string | null;
  setPaymentError: (error: string | null) => void;
}

const PaymentErrorContext = createContext<PaymentErrorContextType>({
  paymentError: null,
  setPaymentError: () => {},
});

export const PaymentErrorProvider = ({ children }: { children: ReactNode }) => {
  const [paymentError, setPaymentError] = useState<string | null>(null);

  return (
    <PaymentErrorContext.Provider value={{ paymentError, setPaymentError }}>{children}</PaymentErrorContext.Provider>
  );
};

export const usePaymentErrorContext = (): PaymentErrorContextType => {
  return useContext(PaymentErrorContext);
};
