import { createContext } from "react";

import { StripePaymentMethod, PaymentMethod } from "@simplyk/common";
import { ExpressPaymentType } from "@stripe/stripe-js";

export type ExpressCheckoutWallet = ExpressPaymentType;

interface StripeContextProps {
  hasPad: boolean;
  hasAch: boolean;
  stripePaymentMethodTypes: StripePaymentMethod[];
  stripePaymentMethods: PaymentMethod[];
  zeffyPaymentMethods: PaymentMethod[];
  allowStripeLink: boolean;
  isStripeLinkFlagLoading: boolean;
  expressCheckoutWallet: ExpressCheckoutWallet | undefined;
  setExpressCheckoutWallet: React.Dispatch<React.SetStateAction<ExpressCheckoutWallet | undefined>>;
}

export const StripeContext = createContext<StripeContextProps>({
  hasPad: false,
  hasAch: false,
  stripePaymentMethodTypes: [],
  stripePaymentMethods: [],
  zeffyPaymentMethods: [],
  allowStripeLink: false,
  isStripeLinkFlagLoading: false,
  expressCheckoutWallet: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setExpressCheckoutWallet: () => {},
});
