import Grid from "@mui/material/Grid";
import { FormType } from "@simplyk/common";
import { Control, FieldErrors, FieldValues, Path, useWatch } from "react-hook-form";

import { usePreviewContext } from "../../features/LiveFormEditor/LivePreview/context/PreviewContext";
import { getErrorMessages } from "../../helpers/reactHookForm";
import { useTranslate } from "../../hooks/useTranslate";

import { AmplitudeEvents } from "@/constants/amplitude";
import { Checkbox } from "@/design-system/Checkbox/Checkbox";
import { TextField } from "@/design-system/TextField/TextField";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";

export type GiveAsOrganismProps<T extends FieldValues> = {
  control: Control<T>;
  amplitudePayload: Record<string, unknown>;
  errors: FieldErrors<T>;
  formType: FormType;
};

export const GiveAsOrganism = <T extends FieldValues>({
  control,
  amplitudePayload,
  errors,
  formType,
}: React.PropsWithChildren<GiveAsOrganismProps<T>>) => {
  const { t } = useTranslate();
  const { logAmplitudeEventCallback } = useAmplitude();
  const giveAsOrganism = "giveAsOrganism" as Path<T>;
  const companyName = "companyName" as Path<T>;
  const checked = !!useWatch({ control, name: giveAsOrganism });
  const { isPreview } = usePreviewContext();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Checkbox
          control={control}
          size="small"
          name={giveAsOrganism}
          checked={checked}
          errorMessage={errors[giveAsOrganism]?.message as string}
          label={
            formType === FormType.Ticketing ? t("ticketing", "giveAsOrganism") : t("donationForm", "giveAsOrganism")
          }
          onClick={logAmplitudeEventCallback(AmplitudeEvents.DonorFormInputChanged, {
            label: "giveAsOrganism",
            errors: getErrorMessages({
              keys: Object.keys(errors),
              errors,
            }),
            ...amplitudePayload,
          })}
        />
      </Grid>

      {checked && (
        <Grid item xs={12} marginTop={1}>
          <TextField
            control={control}
            name={companyName}
            type="text"
            label={t("ticketing", "companyName")}
            errorMessage={errors[companyName]?.message as string}
            data-test="ticket-form-companyname"
            disableInjectingGlobalStyles={isPreview}
            rules={[{ required: { value: true, message: t("ticketing", "companyNameRequired") } }]}
            onBlur={logAmplitudeEventCallback(AmplitudeEvents.DonorFormInputChanged, {
              label: "companyName",
              errors: getErrorMessages({
                keys: Object.keys(errors),
                errors,
              }),
              ...amplitudePayload,
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};
