// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { useContext } from "react";

import Box from "@mui/material/Box"; // Import Box
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Locales } from "@simplyk/common";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useTranslate } from "../../hooks/useTranslate";

import { DialogProps } from "@/design-system/Dialog";
import { IconButton } from "@/design-system/IconButton/IconButton";
import { Cross as CloseIcon } from "@/icons/outlined";

type ZeffyLearnMoreProps = Pick<DialogProps, "open" | "onClose">;

export const ZeffyLearnMore: React.FunctionComponent<React.PropsWithChildren<ZeffyLearnMoreProps>> = (
  props: ZeffyLearnMoreProps
) => {
  const { t } = useTranslate();
  const { organization } = useContext(FrontendFormContext);
  const { locale } = useLocaleContext();

  return (
    <Dialog fullWidth {...props} maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6">{t("donationForm", "zeffyLearnMore.title")}</Typography>{" "}
        <IconButton
          onClick={props.onClose}
          vibe="neutral"
          size="large"
          sx={(theme) => ({
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.text.primary,
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {t("donationForm", "zeffyLearnMore.description", { organizationName: organization.name })}{" "}
          <Typography fontWeight="600" variant="body2" component="span">
            {t("donationForm", "zeffyLearnMore.uniqueFeature")}{" "}
          </Typography>
          <Typography fontWeight="600" variant="body2" sx={(theme) => ({ marginTop: theme.spacing(3) })}>
            {t("donationForm", "zeffyLearnMore.callToAction")}
          </Typography>
          <Box component="ul" sx={{ pl: 2, mt: 1 }}>
            {[
              t("donationForm", "zeffyLearnMore.list.text1"),
              t("donationForm", "zeffyLearnMore.list.text2"),
              t("donationForm", "zeffyLearnMore.list.text3"),
            ].map((contribution, index) => (
              <Box
                component="li"
                key={index}
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                })}
              >
                {contribution}
              </Box>
            ))}
          </Box>
        </Typography>

        {locale === Locales.EN && (
          <Box sx={(theme) => ({ mt: 3, pt: 3, textAlign: "center", borderTop: `1px solid ${theme.palette.divider}` })}>
            <Box
              component="iframe"
              src="https://fast.wistia.com/embed/medias/2d6hysapuf"
              sx={(theme) => ({
                width: "100%",
                maxWidth: "303px",
                aspectRatio: "303 / 170", // Maintain the aspect ratio
                borderRadius: 1,
                border: `1px solid ${theme.palette.primary.main}`,
              })}
              frameBorder="0"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              title="Learn about Zeffy"
            />
            <Typography variant="caption" sx={{ mt: 0.5 }} component="div">
              Watch this 2 min. video to learn more about Zeffy
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
