import * as React from "react";

import { getCountry } from "@simplyk/common";
import { Region } from "country-region-data";

export const useCountryRegion = (countryName: string | undefined) => {
  const currentCountry = React.useMemo(() => (countryName ? getCountry(countryName) : undefined), [countryName]);

  // Apply Quebec accent correction
  const currentCountryRegions: Region[] = React.useMemo(() => {
    if (!currentCountry) {
      return [];
    }

    return currentCountry.regions.map((region) => {
      if (region.name === "Quebec") {
        return {
          ...region,
          name: "Québec",
        };
      }
      return region;
    });
  }, [currentCountry]);

  return { currentCountry, currentCountryRegions };
};
