import * as React from "react";

import { Box } from "@mui/system";

import { useTranslate } from "../../hooks/useTranslate";
import Image from "../Image/Image";

import { useGiveHeartButton, GiveWithHeartButtonProps } from "./useGiveWithHeartButton";

import { Button } from "@/design-system/Button";
import { Lock } from "@/icons/outlined";

export const GiveWithHeartButton: React.FunctionComponent<React.PropsWithChildren<GiveWithHeartButtonProps>> = (
  props
) => {
  const { heartUrl } = useGiveHeartButton(props);

  const { t } = useTranslate();
  return (
    <Button
      type="submit"
      variant="filled"
      data-test="donate"
      vibe="brand"
      size="large"
      onClick={props.onSubmit}
      disabled={props.disabled}
      fullWidth
    >
      {props.showProceedMessage ? t("common", "paymentMethod.button.proceedToPayment") : props.wording}

      <Box
        sx={{
          marginLeft: "0.5rem",
          display: "inline-flex",
          paddingBottom: "2px",
        }}
      >
        {props.showProceedMessage ? (
          <Lock width={20} height={20} />
        ) : (
          <Image alt="heart" src={heartUrl} width={20} height={20} />
        )}
      </Box>
    </Button>
  );
};
