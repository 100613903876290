import { useCallback, useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";

export const useSplitCoverFees = () => {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { logAmplitudeEvent } = useAmplitude();

  const [splitCoverFeesClosed, setSplitCoverFeesClosed] = useState<boolean>(false);

  const { fees, prioritizeBankPayment, selectedPaymentMethodIsCard, tip, updateFreeTipAmount } =
    useContext(FrontendFormContext);

  const shouldShowSplitCoverFeesInfobox = prioritizeBankPayment && selectedPaymentMethodIsCard && !tip;

  useEffect(() => {
    // When we show the split/cover fees infobox, we reset the close state
    if (shouldShowSplitCoverFeesInfobox) {
      setSplitCoverFeesClosed(false);
      logAmplitudeEvent(AmplitudeEvents.FormShowSplitOrCoverFees);
    }
  }, [logAmplitudeEvent, shouldShowSplitCoverFeesInfobox]);

  const handleCoverFees = useCallback(() => {
    updateFreeTipAmount(fees);
    enqueueSnackbar(t("common", "paymentMethod.coverFeesMessage"), { vibe: "positive" });
    logAmplitudeEvent(AmplitudeEvents.FormClickPaymentMethodCoverFees);
    logAmplitudeEvent(AmplitudeEvents.FormClickSplitOrCoverFees, { feesAmount: "cover" });
  }, [enqueueSnackbar, fees, logAmplitudeEvent, t, updateFreeTipAmount]);

  const handleSplitFees = useCallback(() => {
    const flooredSplitFees = Math.floor(fees / 2);
    updateFreeTipAmount(flooredSplitFees);
    enqueueSnackbar(t("common", "paymentMethod.coverFeesMessage"), { vibe: "positive" });
    logAmplitudeEvent(AmplitudeEvents.FormClickPaymentMethodSplitFees);
    logAmplitudeEvent(AmplitudeEvents.FormClickSplitOrCoverFees, { feesAmount: "split" });
  }, [enqueueSnackbar, fees, logAmplitudeEvent, t, updateFreeTipAmount]);

  const onSplitCoverFeesClose = useCallback(() => {
    setSplitCoverFeesClosed(true);
    logAmplitudeEvent(AmplitudeEvents.FormClickCloseSplitOrCoverFees);
  }, [logAmplitudeEvent]);

  return {
    handleCoverFees,
    handleSplitFees,
    onSplitCoverFeesClose,
    showSplitCoverFeesInfobox: shouldShowSplitCoverFeesInfobox && !splitCoverFeesClosed,
  };
};
