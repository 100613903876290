import * as React from "react";

import Grid from "@mui/material/Grid";

import { useTranslate } from "../../hooks/useTranslate";

import { Infobox } from "@/design-system/Infobox";

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage: React.FunctionComponent<React.PropsWithChildren<ErrorMessageProps>> = ({ message }) => {
  const { t } = useTranslate();
  return (
    <>
      <Infobox vibe="danger">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {message} - {t("common", "tryAgainOrContactUs")}
          </Grid>
        </Grid>
      </Infobox>
    </>
  );
};
