import { useCallback, useContext, useRef } from "react";

import { SelectChangeEvent } from "@mui/material/Select";
import { getDollarValue, getTipAmountFromPercentage } from "@simplyk/common";
import { FieldError } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useTranslate } from "../../hooks/useTranslate";

import { AmplitudeEvents } from "@/constants/amplitude";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";

export const useTipInput = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { t } = useTranslate();
  const {
    handleChangeTips: changeTips,
    freeTips,
    selectedTip,
    suggestedTips,
    category,
    displayedFormAmount,
    formData,
    organization,
    getTipChoice,
  } = useContext(FrontendFormContext);
  const freeTipInput = useRef<HTMLDivElement | null>(null);

  const handleChangeTips = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      logAmplitudeEvent(AmplitudeEvents.DonorFormTipChanged, {
        amount: getDollarValue(displayedFormAmount),
        form_id: formData.id,
        form_type: category,
        organization_country: organization.country,
        tip_choice: getTipChoice(event.target.value as string),
        tip_amount: getDollarValue(getTipAmountFromPercentage(displayedFormAmount, Number(event.target.value))),
      });
      setTimeout(() => {
        if (event.target.value === "free" && freeTipInput?.current) {
          freeTipInput.current.focus();
        }
      }, 100);
      changeTips(event);
    },
    [logAmplitudeEvent, displayedFormAmount, formData.id, category, organization.country, getTipChoice, changeTips]
  );

  const errorOnNegativeTip =
    freeTips < 0 &&
    ({
      type: "min",
      message: t("donationForm", "tipsIsGreaterThanZero"),
    } as FieldError);

  const isMiddleTipSelected = selectedTip && suggestedTips?.[1].amount === selectedTip.amount;

  return {
    freeTipInput,
    errorOnNegativeTip,
    handleChangeTips,
    isMiddleTipSelected,
  };
};
