/* eslint-disable sonarjs/no-duplicate-string */

import { stringify } from "querystring";

import { useContext } from "react";

import {
  FormCategory,
  FormType,
  ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE,
  FrontendRoutes,
  getDonationOrTicketingFormLink,
  PaymentMethod,
} from "@simplyk/common";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { inferRouterOutputs } from "@trpc/server";
import { UseFormSetValue } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { OrganizationObject } from "../../gql/gql-types";
import { getBillingDetails, getFundraisingCreationInput } from "../../helpers/payment";
import { captureSentryError, captureSentryMessage } from "../../helpers/sentry";
import { AppRouterType } from "../../helpers/trpc";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";
import { useSubmitCommand } from "../PaymentProcessor/hooks/useSubmitCommand";
import { isPaymentFailed } from "../PaymentProcessor/type";

import { HandleErrorParams, unknownPaymentErrorCode } from "./helper";

import { AmplitudeEvents } from "@/constants/amplitude";
import { PaymentMode } from "@/helpers/stripe";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";
import { useCreateRegistrationCampaigns } from "@/hooks/useCreateRegistrationCampaigns";
import { useLocaleContext } from "@/src/contexts/LocaleContext";
import { TicketingPaymentInput } from "@/types/ticketing";

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];

export type GetThankYouUrl = ({
  paymentInput,
  command,
  formType,
  isExpressCheckout = false,
  organization,
}: {
  paymentInput: DonationFormPaymentInput | TicketingPaymentInput;
  command: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];
  formType: FormType;
  isExpressCheckout?: boolean;
  organization: OrganizationObject;
}) => string;

export const useCashapp = ({
  setValue,
  handleError,
  getThankYouUrl,
}: {
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  handleError: (params: HandleErrorParams) => void;
  getThankYouUrl: GetThankYouUrl;
}) => {
  const { handleSubmitCommand } = useSubmitCommand({ setValue });
  const { t } = useTranslate();
  const { organization, commandId, formType, category, postTransactionUrl, generateETicket, formData } =
    useContext(FrontendFormContext);
  const { hasADate } = useContext(FrontendTicketingContext);
  const stripe = useStripe();
  const { logAmplitudeEvent } = useAmplitude();
  const { shouldCreateCampaigns } = useCreateRegistrationCampaigns();
  const { locale, isoLocale } = useLocaleContext();

  const payByCashapp = async (submitCommandInput: SubmitCommandFormInput, paymentMode: PaymentMode) => {
    if (!stripe) {
      logAmplitudeEvent(AmplitudeEvents.FormClickSubmitPaymentError, {
        step: "stripe-load",
        commandId,
        code: "stripe_load_error",
        isUnknown: true,
        paymentMethod: PaymentMethod.CashApp,
        formType,
        formCategory: category,
      });
      captureSentryError({ message: "PaymentError: Stripe Sdk not found" });
      return;
    }

    captureSentryMessage({
      message: "Start submit command",
      params: {
        commandId,
        formType,
        organizationId: organization?.id,
        category: category ? category.toString() : "null",
      },
    });

    const paymentInput = submitCommandInput.paymentInput;
    const createFundraisingPayload = shouldCreateCampaigns(paymentInput, submitCommandInput.formType)
      ? getFundraisingCreationInput(
          {
            email: paymentInput.email,
            firstName: paymentInput.firstName,
            lastName: paymentInput.lastName,
          },
          paymentInput,
          locale,
          organization?.country
        )
      : undefined;

    const submitResponse = await handleSubmitCommand({
      ...submitCommandInput,
      createFundraisingPayload,
      paymentMethod: PaymentMethod.CashApp,
    });

    if (submitResponse && isPaymentFailed(submitResponse)) {
      handleError({
        message:
          submitResponse.error.code === ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            ? ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            : submitResponse.error.message,
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: submitResponse.error.code,
          message: submitResponse.error.message,
          isUnknown: false,
          paymentMethod: PaymentMethod.CashApp,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const clientSecret = submitResponse.data?.clientSecret;
    const command = submitResponse.data?.command;
    if (!clientSecret || !command) {
      handleError({
        message: t("common", "unknown_error", { code: unknownPaymentErrorCode.cashApp1.code }),
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: !clientSecret ? "missing_client_secret" : "missing_command",
          isUnknown: true,
          paymentMethod: PaymentMethod.CashApp,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const thankYouUrl = getThankYouUrl({
      paymentInput: submitCommandInput.paymentInput,
      command,
      formType,
      isExpressCheckout: submitCommandInput.isExpressCheckout,
      organization,
    });

    const formUrl = getDonationOrTicketingFormLink({
      form: {
        id: formData.id,
        path: formData.path,
        formType,
        formCategory: category as FormCategory,
      },
      isoLocale,
    });
    const params = {
      commandId,
      thankYouUrl: thankYouUrl || "",
      isoLocale,
      postTransactionUrl: postTransactionUrl || "",
      organizationName: organization.name,
      formType: submitCommandInput.formType,
      hasADate: hasADate ? "true" : "false",
      generateETicket: generateETicket && command.productsAmount > 0 ? "true" : "false",
      generateTaxReceipt: (command.eligibleAmount || 0) > 0 && organization.canGenerateReceipt ? "true" : "false",
      formUrl,
    };
    const returnUrl = `${process.env.NEXT_PUBLIC_FRONTEND_URL}${FrontendRoutes.PostFormSubmit}?${stringify(params)}`;
    let confirmError: StripeError | undefined;
    if (paymentMode === "setup") {
      const { error } = await stripe.confirmCashappSetup(clientSecret, {
        return_url: returnUrl,
        payment_method: {
          billing_details: getBillingDetails(submitCommandInput.paymentInput, organization),
        },
      });
      confirmError = error;
    } else {
      const { error } = await stripe.confirmCashappPayment(clientSecret, {
        return_url: returnUrl,
        payment_method: {
          billing_details: getBillingDetails(submitCommandInput.paymentInput, organization),
        },
      });
      confirmError = error;
    }

    if (confirmError) {
      handleError({
        message: confirmError.message || t("common", "unknown_error", { code: unknownPaymentErrorCode.cashApp2.code }),
        commandId,
        metadata: {
          step: "manual-confirmation",
          commandId,
          code: confirmError.code,
          isUnknown: false,
          paymentMethod: PaymentMethod.CashApp,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });
    }
  };

  return { payByCashapp };
};
