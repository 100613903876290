import * as React from "react";

import { useTheme } from "@mui/material";

import { useTranslate } from "../../hooks/useTranslate";
import { Typography } from "../design-system/Typography/Typography";
import { NewTabLink } from "../Link/NewTabLink";

export const UserAgreement: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate();
  const theme = useTheme();
  return (
    <Typography variant="caption" vibe="text-form-quiet">
      {t("donationForm", "userAgreement.thisSite")}
      <NewTabLink
        href="https://policies.google.com/privacy"
        display="inline"
        sx={{ color: `${theme.palette.text.form.quiet} !important` }}
      >
        {t("donationForm", "userAgreement.privacyPolicy")}
      </NewTabLink>
      {t("donationForm", "userAgreement.and")}
      <NewTabLink
        href="https://policies.google.com/terms"
        display="inline"
        sx={{ color: `${theme.palette.text.form.quiet} !important` }}
      >
        {t("donationForm", "userAgreement.termOfService")}
      </NewTabLink>
      {t("donationForm", "userAgreement.apply")}
      <NewTabLink
        href="https://support.zeffy.com/data-privacy-policy"
        display="inline"
        sx={{ color: `${theme.palette.text.form.quiet} !important` }}
      >
        {t("donationForm", "userAgreement.privacyPolicy")}
      </NewTabLink>
      {t("donationForm", "userAgreement.end")}
    </Typography>
  );
};
