/* eslint-disable sonarjs/no-duplicate-string */

import { ReactElement, useCallback, useContext, useEffect } from "react";

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { FormType, PaymentMethod } from "@simplyk/common";
import { ExpressCheckoutElement as StripeExpressCheckoutElement } from "@stripe/react-stripe-js";
import { enqueueSnackbar } from "notistack";
import { FieldValues } from "react-hook-form";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useIsFormV2 } from "../../features/FormV2/hooks/useIsFormV2";
import { usePreviewContext } from "../../features/LiveFormEditor/LivePreview/context/PreviewContext";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { Infobox } from "../design-system/Infobox";
import { Typography } from "../design-system/Typography";
import { DisplayGrid } from "../Display/DisplayGrid";
import { DisplayWithInfoFormPreview } from "../InfoForm/InfoForm";
import PaymentErrorAlert from "../PaymentErrorAlert/PaymentErrorAlert";
import { PaymentMethodOptions } from "../PaymentMethodOptions/PaymentMethodOptions";
import { PreviewPaymentMethods } from "../PaymentMethodOptions/PreviewPaymentMethods";
import { PaymentElementProps } from "../PaymentProcessor/type";
import { SubmitPaymentButton } from "../SubmitPaymentButton/SubmitPaymentButton";
import { UserAgreement } from "../UserAgreement/UserAgreement";

import { usePaymentErrorContext } from "./PaymentErrorContext";
import { useExpressCheckout } from "./useExpressCheckout";
import { usePaymentSubmit } from "./usePaymentSubmit";
import { useSplitCoverFees } from "./useSplitCoverFees";
import { useStyles } from "./useStyles";

import { Divider } from "@/design-system/Divider/Divider";
import ApplePayButtonDesktop from "@/icons/illustration/apple_pay_button_desktop.svg";
import ApplePayButtonMobile from "@/icons/illustration/apple_pay_button_mobile.svg";
import GooglePayButtonDesktop from "@/icons/illustration/google_pay_button_desktop.svg";
import GooglePayButtonMobile from "@/icons/illustration/google_pay_button_mobile.svg";
import { Success } from "@/icons/outlined";
import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { StripeContext } from "@/src/contexts/StripeContext";

export function PaymentElement<T extends FieldValues>({
  handleSubmit,
  formObject,
  validDiscount,
  setValue,
  trigger,
  errors,
  control,
  billingDetails,
  shouldDisplaySubmitButton = true,
}: PaymentElementProps<T>): ReactElement<PaymentElementProps<T>> {
  const isFormV2 = useIsFormV2();
  const { t } = useTranslate();
  const styles = useStyles();
  const { logAmplitudeEvent } = useAmplitude();
  const { paymentError, setPaymentError } = usePaymentErrorContext();
  const { allowStripeLink, isStripeLinkFlagLoading } = useContext(StripeContext);
  const { handleFormSubmit } = usePaymentSubmit({
    handleSubmit,
    formObject,
    validDiscount,
    setValue,
    trigger,
    errors,
    control,
  });
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();
  const { ticketingField } = useContext(FrontendTicketingContext);

  const {
    organization,
    formType,
    tip,
    isOnboarding,
    prioritizeBankPayment,
    selectedPaymentMethodIsBank,
    selectedPaymentMethodIsCard,
    formattedFees,
    displayExpressCheckout,
    isExpressCheckoutLoading,
    expressCheckoutDisabled,
    allowedPaymentMethods,
    isPreviewTemplateMode,
    isPaymentDisabled,
  } = useContext(FrontendFormContext);

  const { isPreview: isLiveFormEditorPreview } = usePreviewContext();

  useEffect(() => {
    if (prioritizeBankPayment) {
      logAmplitudeEvent(AmplitudeEvents.FormInsertAmountZeffyModelMessage, {
        abTestMessage: "positive",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prioritizeBankPayment]);

  const {
    expressCheckoutKey,
    showExpressCheckoutLoader,
    handleExpressCheckoutReady,
    handleExpressCheckoutClick,
    handleExpressCheckoutConfirm,
    handleExpressCheckoutLoadError,
  } = useExpressCheckout({ handleFormSubmit, setValue, trigger, control, errors });

  const { handleCoverFees, handleSplitFees, onSplitCoverFeesClose, showSplitCoverFeesInfobox } = useSplitCoverFees();

  const showExpressCheckoutOnboardingSnackbar = useCallback(() => {
    enqueueSnackbar(t("common", "payment.expressCheckout.onboarding"), { vibe: "danger" });
  }, [t]);

  useEffect(() => {
    if (isPaymentDisabled) {
      setPaymentError(
        t("common", "paymentNotReadyBanner.description.disabled", {
          orgEmail: organization?.ownerEmail || "contact@zeffy.com",
        })
      );
    }
  }, [isPaymentDisabled, organization?.ownerEmail, paymentError, setPaymentError, t]);

  return (
    <Grid container>
      <DisplayGrid when={Boolean(paymentError)} item xs={12} marginBottom={isFormV2 ? 0 : 2}>
        <PaymentErrorAlert paymentError={paymentError} />
      </DisplayGrid>
      <DisplayWithInfoFormPreview
        content={t(formType === FormType.DonationForm ? "donationForm" : "ticketing", "preview.paymentMethod")}
      >
        {!expressCheckoutDisabled && !isPreviewTemplateMode && !isStripeLinkFlagLoading && (
          <DisplayGrid when={displayExpressCheckout} item container xs={12} marginBottom={3}>
            <Grid item xs={12} marginBottom={3}>
              <StripeExpressCheckoutElement
                // To avoid any issues with express checkout not loading we use the key prop
                // to force the express checkout component to re-initialize when it's shown/hidden
                key={expressCheckoutKey}
                id="express-checkout-element"
                // We remove the onReady callback to avoid triggering this callback when hiding the component
                onReady={handleExpressCheckoutReady}
                onLoadError={handleExpressCheckoutLoadError}
                onConfirm={handleExpressCheckoutConfirm}
                onClick={isOnboarding ? showExpressCheckoutOnboardingSnackbar : handleExpressCheckoutClick}
                {...(allowStripeLink ? {} : { options: { paymentMethods: { link: "never" } } })}
              />
              {showExpressCheckoutLoader && <Skeleton variant="rectangular" height={44} />}
            </Grid>

            <Grid container item xs={12} alignItems="center" justifyContent="space-between">
              <Grid item xs>
                <Divider sx={{ borderColor: theme.palette.border.form.moderate }} />
              </Grid>
              <Grid item marginRight={2} marginLeft={2}>
                <Typography variant="subtitle2" className={styles.expressSeparator}>
                  {t("common", "payment.express.payAnotherWay")}
                </Typography>
              </Grid>
              <Grid item xs>
                <Divider sx={{ borderColor: theme.palette.border.form.moderate }} />
              </Grid>
            </Grid>
          </DisplayGrid>
        )}

        {/* Fake Express Checkout Buttons for Preview */}
        {(isPreviewTemplateMode || isLiveFormEditorPreview) && (
          <>
            <Grid item xs={12} marginBottom={3}>
              {isSmallScreen ? (
                <>
                  <ApplePayButtonMobile />
                  <GooglePayButtonMobile />
                </>
              ) : (
                <>
                  <ApplePayButtonDesktop />
                  <GooglePayButtonDesktop />
                </>
              )}
            </Grid>
            <Grid container item xs={12} alignItems="center" justifyContent="space-between" marginBottom={3}>
              <Grid item xs>
                <Divider sx={{ borderColor: theme.palette.border.form.moderate }} />
              </Grid>
              <Grid item marginRight={2} marginLeft={2}>
                <Typography variant="subtitle2" className={styles.expressSeparator}>
                  {t("common", "payment.express.payAnotherWay")}
                </Typography>
              </Grid>
              <Grid item xs>
                <Divider sx={{ borderColor: theme.palette.border.form.moderate }} />
              </Grid>
            </Grid>
          </>
        )}

        {(!isExpressCheckoutLoading || isPreviewTemplateMode) && !isLiveFormEditorPreview && (
          <Grid item xs={12}>
            <PaymentMethodOptions offlinePaymentWording={ticketingField?.offlinePaymentWording} />
          </Grid>
        )}

        {/* Live Form Editor Preview (Fake payment methods) */}
        {isLiveFormEditorPreview && (
          <Grid item xs={12} marginBottom={6}>
            <PreviewPaymentMethods country={organization.country} />
          </Grid>
        )}
      </DisplayWithInfoFormPreview>
      {billingDetails && (!isExpressCheckoutLoading || isPreviewTemplateMode) && (
        <Grid item xs={12} marginTop={4}>
          {billingDetails}
        </Grid>
      )}

      {prioritizeBankPayment &&
        selectedPaymentMethodIsBank &&
        (isFormV2 ? (
          <Grid item xs={12} marginTop={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  margin: 0,
                  backgroundColor: theme.palette.surface.form.quiet,
                  padding: theme.spacing(2),
                  borderRadius: theme.radius(1),
                }}
              >
                <Grid container spacing={2} alignItems={isSmallScreen ? "flex-start" : "center"} flexDirection="row">
                  <Grid item>
                    <Success width="24" height="24" fill={theme.palette.surface.brand.intense} />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{
                      paddingLeft: isSmallScreen ? 0 : theme.spacing(1),
                    }}
                  >
                    <Typography variant="body2" align="justify" vibe="text-form-moderate" component="span">
                      {t("common", "payment.positiveZeffyCoversFees", { orgName: organization.name })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} marginTop={4}>
            <Infobox vibe="positive">
              {t("common", "payment.positiveZeffyCoversFees", { orgName: organization.name })}
            </Infobox>
          </Grid>
        ))}

      {prioritizeBankPayment &&
        selectedPaymentMethodIsCard &&
        tip !== 0 &&
        (allowedPaymentMethods.includes(PaymentMethod.Ach) || allowedPaymentMethods.includes(PaymentMethod.Pad)) && (
          <Grid item xs={12} marginTop={4}>
            <Infobox vibe="brand">{t("common", "payment.infoZeffyCoversFees", { orgName: organization.name })}</Infobox>
          </Grid>
        )}

      {showSplitCoverFeesInfobox && (
        <Grid item xs={12} marginTop={4}>
          <Infobox
            title={t("common", "payment.coverFees.title")}
            vibe="brand"
            buttonsProps={[
              {
                key: "splitFees",
                children: t("common", "payment.coverFees.splitFees"),
                onClick: handleSplitFees,
              },
              {
                key: "coverFees",
                children: t("common", "payment.coverFees.coverFees", { formattedFees }),
                onClick: handleCoverFees,
              },
            ]}
            onClose={onSplitCoverFeesClose}
          >
            {t("common", "payment.coverFees.messageA", { orgName: organization.name })}
          </Infobox>
        </Grid>
      )}

      {shouldDisplaySubmitButton && (
        <Grid container item xs={12} marginTop={4} marginBottom={2}>
          <SubmitPaymentButton handleFormSubmit={handleFormSubmit} />
          <Grid item xs={12} marginTop={1}>
            <UserAgreement />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
