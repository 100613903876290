import { useContext, useMemo } from "react";

import {
  StripeRecurringInterval,
  MembershipValidityPeriod,
  getCurrencyFromCountry,
  formatDateToShortDate,
  getField,
  formatSmartNarrowCurrencyFromCents,
} from "@simplyk/common";
import { Control, useWatch } from "react-hook-form";

import { useFrontendAuctionContext } from "../../../contexts/FrontendAuctionContext";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../../contexts/LocaleContext";
import { useTranslate } from "../../../hooks/useTranslate";

import { TicketingPaymentInput } from "@/types/ticketing";

interface DescriptionData {
  description: string;
  price: string;
  isNew: boolean;
  recurrenceExpirationInfo: string;
}

export const useTicketsDescriptions = ({ control }: { control: Control<TicketingPaymentInput> }) => {
  const { t } = useTranslate();
  const ticketsPurchased = useWatch({ control, name: "ticketsPurchased" });
  const { ticketing, isAuction, membershipRenewalBuyerInfo } = useContext(FrontendTicketingContext);
  const { commandBidsRateIdsMap, hasAlreadyBid, highestBidsMapByRateId } = useFrontendAuctionContext();
  const { organization, stripeRecurrenceInterval } = useContext(FrontendFormContext);
  const { locale, isoLocale } = useLocaleContext();
  const currency = getCurrencyFromCountry(organization.country);

  const displayRecurrenceInterval = useMemo(() => {
    if (!stripeRecurrenceInterval) {
      return "";
    }
    return ` / ${
      stripeRecurrenceInterval === StripeRecurringInterval.year
        ? t("dashboard", "common.year")
        : t("dashboard", "common.month")
    }`;
  }, [stripeRecurrenceInterval, t]);

  const displayRecurrenceExpirationInfo = useMemo(() => {
    if (membershipRenewalBuyerInfo?.validityPeriod === MembershipValidityPeriod.NoExpiration) {
      return "";
    }
    return stripeRecurrenceInterval
      ? t("ticketing", "payment.summary.cancelAnyTime")
      : t("ticketing", "payment.summary.expires", {
          date: membershipRenewalBuyerInfo?.expirationDate
            ? formatDateToShortDate(membershipRenewalBuyerInfo?.expirationDate, locale)
            : "",
        });
  }, [
    membershipRenewalBuyerInfo?.validityPeriod,
    membershipRenewalBuyerInfo?.expirationDate,
    stripeRecurrenceInterval,
    t,
    locale,
  ]);

  const ticketsDescriptions = useMemo(
    () =>
      ticketsPurchased?.reduce<DescriptionData[]>((prev, current) => {
        const rate = ticketing.rates?.find((rate) => rate.id === current.rateId);

        if (!rate || ((!current.purchasedNumber || current.purchasedNumber <= 0) && !current.bid)) {
          return prev;
        }
        const rateField = getField(rate.rateFields, locale);
        const price = `${formatSmartNarrowCurrencyFromCents(
          (isAuction ? current.bid : rate.amount * (current.purchasedNumber || 0)) || 0,
          { locale: isoLocale, currency }
        )}${displayRecurrenceInterval}`;
        const description = `${current.purchasedNumber}x ${rateField?.title || ""}`;
        const recurrenceExpirationInfo = rate.membershipValidityPeriod ? displayRecurrenceExpirationInfo : "";
        return [...prev, { description, price, recurrenceExpirationInfo, isNew: false }];
      }, []),
    [
      currency,
      displayRecurrenceExpirationInfo,
      displayRecurrenceInterval,
      isAuction,
      isoLocale,
      locale,
      ticketing.rates,
      ticketsPurchased,
    ]
  );

  const bidsDescriptions = useMemo(() => {
    const newBidsPurchased =
      ticketsPurchased
        ?.filter((ticket) => ticket.bid && ticket.bid > 0)
        ?.map((ticket) => ({ amount: ticket.bid, rateId: ticket.rateId, isNew: true })) || [];

    const oldWinningBidsPurchased = commandBidsRateIdsMap
      ? Object.values(commandBidsRateIdsMap)
          ?.filter((bid) => {
            return (
              !ticketsPurchased?.find((ticket) => ticket.rateId === bid.rateId && ticket.bid && ticket.bid > 0) &&
              highestBidsMapByRateId?.[bid.rateId]?.amount === bid.amount
            );
          })
          .map((bid) => ({ amount: bid.amount, rateId: bid.rateId, isNew: false }))
      : [];
    const allBids = [...newBidsPurchased, ...oldWinningBidsPurchased];

    return allBids.reduce<DescriptionData[]>((prev, current) => {
      const rate = ticketing.rates?.find((rate) => rate.id === current.rateId);
      if (!rate) {
        return prev;
      }
      const rateField = getField(rate.rateFields, locale);
      const price = formatSmartNarrowCurrencyFromCents(current.amount || 0, { locale: isoLocale, currency });
      const description = `${t("ticketing", "auction.payment.bidOn")} ${rateField?.title || ""}`;
      return [...prev, { description, price, isNew: hasAlreadyBid && current.isNew, recurrenceExpirationInfo: "" }];
    }, []);
  }, [
    commandBidsRateIdsMap,
    currency,
    hasAlreadyBid,
    highestBidsMapByRateId,
    isoLocale,
    locale,
    t,
    ticketing.rates,
    ticketsPurchased,
  ]);

  return { ticketsDescriptions: isAuction ? bidsDescriptions : ticketsDescriptions };
};
