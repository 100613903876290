/* eslint-disable sonarjs/no-duplicate-string */
import { useContext, useMemo } from "react";

import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { formatCurrencyAmount, getCurrencyFromCountry, getDollarValue } from "@simplyk/common";
import { useWatch, Control } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { DiscountType } from "../../gql/gql-types";
import { getDiscountAmount, getDiscountReduction } from "../../helpers/order";
import { useTranslate } from "../../hooks/useTranslate";
import { Chip } from "../design-system/Chip";
import { Typography } from "../design-system/Typography";
import { useTicketingKeyValues } from "../TicketingDrawers/useTicketingKeyValues";

import { useTicketsDescriptions } from "./hooks/useTicketsDescriptions";

import { TicketingPaymentInput } from "@/types/ticketing";

export const PaymentProcessorTicketSummary = ({
  control,
  vibe: vibeOverride,
}: {
  control: Control<TicketingPaymentInput>;
  vibe?: "text-form-moderate";
}) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const { ticketsPrice } = useTicketingKeyValues({ control });
  const { validDiscount } = useContext(FrontendTicketingContext);
  const { organization } = useContext(FrontendFormContext);
  const { isoLocale } = useLocaleContext();
  const currency = getCurrencyFromCountry(organization.country);
  const vibe = vibeOverride || theme.constants.isFormV2 ? "text-form-moderate" : "text-form-quiet";

  const extraDonation = useWatch({ control, name: "extraDonation" });
  const { ticketsDescriptions } = useTicketsDescriptions({ control });

  const extraDonationValue = useMemo(
    () => extraDonation && formatCurrencyAmount(getDollarValue(extraDonation), isoLocale, currency),
    [currency, extraDonation, isoLocale]
  );

  const discountFormattedValue = useMemo(
    () =>
      validDiscount &&
      formatCurrencyAmount(getDollarValue(-getDiscountAmount(validDiscount, ticketsPrice)), isoLocale, currency),
    [currency, isoLocale, ticketsPrice, validDiscount]
  );

  const discountFormattedReduction = useMemo(
    () => validDiscount && getDiscountReduction(validDiscount, isoLocale, currency),
    [currency, isoLocale, validDiscount]
  );

  return (
    <Grid item xs={12} sx={{ paddingBottom: 2 }}>
      <Grid container spacing={1}>
        {ticketsDescriptions?.map((tickets, index) => {
          return (
            <Grid item xs={12} key={index} data-test="order-ticket-information">
              <Grid container>
                <Grid item xs>
                  <Typography variant="body2" component="span" vibe={vibe} data-test="ticket-description">
                    {tickets.description}
                  </Typography>
                  {tickets.isNew && (
                    <Box marginLeft={1} component="span">
                      <Chip vibe="brand" variant="outlined" label={t("ticketing", "newBid")} />
                    </Box>
                  )}
                  {tickets.recurrenceExpirationInfo && (
                    <Typography variant="subtitle2" component="span" vibe="text-form-quiet" paddingLeft={1}>
                      {tickets.recurrenceExpirationInfo}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  {" "}
                  <Typography variant="body2" component="span" vibe={vibe}>
                    {tickets.price}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        {discountFormattedValue && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" component="span" vibe={vibe}>
                  {t("ticketing", "discountValue")}
                </Typography>
                {validDiscount?.type === DiscountType.Percentage && (
                  <>
                    {" "}
                    <Typography variant="body2" component="span" vibe={vibe}>
                      ({discountFormattedReduction})
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body2" component="span" vibe={vibe}>
                  {discountFormattedValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {Boolean(extraDonationValue) && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs>
                <Typography variant="body2" component="span" vibe={vibe}>
                  {t("ticketing", "extraDonation")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="span" vibe={vibe}>
                  {extraDonationValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
