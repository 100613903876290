import { FunctionComponent, PropsWithChildren } from "react";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";

import { Infobox } from "@/design-system/Infobox";

interface PaymentErrorAlertProps {
  paymentError?: string | null;
}

const PaymentErrorAlert: FunctionComponent<PropsWithChildren<PaymentErrorAlertProps>> = ({ paymentError }) => {
  return (
    <Box
      sx={(theme) => ({
        maxHeight: paymentError ? 1000 : 0,
        transition: paymentError ? "max-height 0.5s ease-in-out" : "none",
        overflow: "hidden",
        marginBottom: paymentError ? theme.spacing(2) : 0,
        zIndex: 10,
      })}
    >
      <Grid item xs={12}>
        <Infobox vibe="danger">{paymentError}</Infobox>
      </Grid>
    </Box>
  );
};

export default PaymentErrorAlert;
