import { stringify } from "querystring";

import { useCallback, useContext } from "react";

import { FrontendRoutes, RouteBuilders, CampaignAction } from "@simplyk/common";
import { useRouter } from "next/router";

import { unknownPaymentErrorCode } from "../components/PaymentElement/helper";
import { isTicketingData } from "../components/PaymentProcessor/helper";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { CreatedCampaignsObject, FormType } from "../gql/gql-types";
import { formatCreatedCampaigns } from "../helpers/navigateToPeerToPeerSuccess";
import { getFundraisingCreationInput } from "../helpers/payment";
import { trpc } from "../helpers/trpc";
import { FRONTEND_URL } from "../routes/routes";

import { useTranslate } from "./useTranslate";

import { DonationFormPaymentInput } from "@/types/donationForm";
import { TicketingPaymentInput } from "@/types/ticketing";

interface CreateCampaignsProps {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  paymentInput: TicketingPaymentInput;
}

export const useCreateRegistrationCampaigns = () => {
  const { locale, isoLocale } = useLocaleContext();
  const { ticketing, isCampaignRegistration } = useContext(FrontendTicketingContext);
  const { commandId, organization, postTransactionUrl } = useContext(FrontendFormContext);

  const { t } = useTranslate();
  const router = useRouter();
  const { isPeerToPeerV2 } = router.query;

  const { mutateAsync: createRegistrationCampaigns } = trpc.createRegistrationCampaigns.useMutation();

  const shouldCreateCampaigns = useCallback(
    (
      paymentInput: TicketingPaymentInput | DonationFormPaymentInput,
      formType: FormType
    ): paymentInput is TicketingPaymentInput => isTicketingData(paymentInput, formType) && isCampaignRegistration,
    [isCampaignRegistration]
  );

  const createCampaigns = useCallback(
    async ({
      firstName,
      lastName,
      email,
      paymentInput,
    }: CreateCampaignsProps): Promise<{ returnUrl?: string | null; error?: string | null }> => {
      const fundraisingInput = getFundraisingCreationInput(
        {
          firstName,
          lastName,
          email,
        },
        paymentInput,
        locale,
        organization.country
      );
      if (!fundraisingInput) {
        return { error: t("common", "unknownError", { code: unknownPaymentErrorCode.other5.code }) };
      }
      const { data } = await createRegistrationCampaigns({
        ticketingId: ticketing.id,
        commandId,
        fundraising: fundraisingInput,
      });
      if (!data) {
        return { error: t("common", "unknownError", { code: unknownPaymentErrorCode.other4.code }) };
      }
      const paymentSucceededData = formatCreatedCampaigns(data as CreatedCampaignsObject);

      const params = {
        formId: paymentSucceededData?.id,
        teamId: paymentSucceededData?.teamId,
        campaignAction: paymentSucceededData?.campaignAction,
        isFundraiserFormEditDisabled: paymentSucceededData?.isFundraiserFormEditDisabled,
        color: paymentSucceededData?.color,
        organizationName: organization.name,
        logoUrl: paymentSucceededData?.logoUrl || undefined,
        multipleCampaigns: paymentSucceededData?.multipleCampaigns,
        token: paymentSucceededData?.token,
      };

      // Save token and formId in local storage
      localStorage.setItem(
        "peer-to-peer-form-data",
        JSON.stringify({
          token: paymentSucceededData?.token,
          formId: paymentSucceededData?.id,
        })
      );

      // For now, users that create campaign through registration are using the old flow, but we need to redirect them to the new form once payment is submitted
      const returnUrlV1 =
        postTransactionUrl ||
        `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${isoLocale}${FrontendRoutes.PeerToPeerSuccess}?${stringify(params)}`;

      const returnUrlV2 =
        paymentSucceededData?.campaignAction === CampaignAction.IndividualFundraising
          ? `${FRONTEND_URL}${
              RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({
                isoLocale,
                path: paymentSucceededData?.id || "",
              }).path
            }?new=true`
          : `${FRONTEND_URL}${
              RouteBuilders.buildPeerToPeerV2TeamCampaignLink({
                isoLocale,
                path: paymentSucceededData?.id || "",
              }).path
            }?new=true`;

      const returnUrl = isPeerToPeerV2 ? returnUrlV2 : returnUrlV1;

      return { returnUrl };
    },
    [
      commandId,
      createRegistrationCampaigns,
      isPeerToPeerV2,
      isoLocale,
      locale,
      organization.country,
      organization.name,
      postTransactionUrl,
      t,
      ticketing.id,
    ]
  );

  return { createCampaigns, shouldCreateCampaigns };
};
