import { ReactElement, ReactNode, ReactPortal, FunctionComponent, useCallback, memo } from "react";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled, Theme } from "@mui/material/styles";

import { Tooltip } from "@/design-system/Tooltip/Tooltip";
import { ZeffyPaletteVibe } from "@/types/mui";

const PREFIX = "BubbleTooltip";

const classes = {
  warning: `${PREFIX}-warning`,
  positive: `${PREFIX}-positive`,
};

const StyledTooltip = memo(
  styled(Tooltip)(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.warning}`]: {
      backgroundColor: theme.palette.surface.warning.intense,
    },

    [`&& .${classes.positive}`]: {
      backgroundColor: theme.palette.surface.positive.intense,
    },
  }))
);

interface BubbleTooltipProps {
  title: boolean | ReactElement<unknown> | number | string | Iterable<ReactNode> | ReactPortal;
  children: ReactElement<unknown>;
  hidden?: boolean;
  offsetBottom?: number;
  forceOpen?: boolean;
  forceClose?: () => void;
  placement: "top" | "right";
  vibe: Extract<ZeffyPaletteVibe, "positive" | "warning">;
}

export const BubbleTooltip: FunctionComponent<React.PropsWithChildren<BubbleTooltipProps>> = ({
  children,
  title,
  hidden,
  forceOpen,
  forceClose,
  placement,
  vibe,
}) => {
  const handleClickAway = useCallback(() => {
    forceClose?.();
  }, [forceClose]);

  if (hidden) {
    return children;
  }

  return (
    <StyledTooltip
      open={forceOpen}
      placement={placement}
      title={title}
      enterTouchDelay={0}
      leaveTouchDelay={10_000}
      classes={{ tooltip: classes[vibe] }}
    >
      <div style={{ width: "100%" }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>{children}</div>
        </ClickAwayListener>
      </div>
    </StyledTooltip>
  );
};
