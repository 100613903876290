import { WheelEvent, FunctionComponent, useContext, useCallback } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { formatCurrencyAmount, getDollarValue } from "@simplyk/common";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { usePreviewContext } from "../../features/LiveFormEditor/LivePreview/context/PreviewContext";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useTranslate } from "../../hooks/useTranslate";

import { useStyles } from "./useStyles";
import { useTipInput } from "./useTipInput";

import { Select } from "@/design-system/Select/Select";
import { TextField } from "@/design-system/TextField/TextField";

export const TipInput: FunctionComponent<
  React.PropsWithChildren<{ disabled?: boolean; onOpen?: () => void; onClose?: () => void }>
> = ({ disabled, onOpen, onClose }) => {
  const { isoLocale } = useLocaleContext();
  const { logAmplitudeEvent } = useAmplitude();
  const { t } = useTranslate();
  const {
    suggestedTips,
    selectedTip,
    isSuggestedTipsAmount,
    freeTips,
    handleChangeFreeTips,
    currency,
    category,
    isEmbed,
    isAuction,
    organization,
    formData,
    logAmplitudeTipsChanged,
  } = useContext(FrontendFormContext);

  const classes = useStyles();
  const { freeTipInput, errorOnNegativeTip, handleChangeTips } = useTipInput();
  const { isPreview } = usePreviewContext();

  const handleOpen = useCallback(() => {
    onOpen?.();
    logAmplitudeEvent(AmplitudeEvents.FormClickTipDropdown, {
      form_type: category,
      organization_country: organization.country,
      form_id: formData.id,
    });
  }, [category, formData.id, logAmplitudeEvent, onOpen, organization.country]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Select
          disableAutoFocus={isEmbed}
          value={selectedTip.isFree ? "free" : selectedTip.percentage}
          onChange={handleChangeTips}
          onOpen={handleOpen}
          onClose={handleClose}
          disabled={disabled}
          style={{ minWidth: 100 }}
          inputLabelClasses={{ helperText: classes.helperText }}
          disableInjectingGlobalStyles={isPreview}
          helperText={
            isAuction
              ? selectedTip.isFree
                ? t("ticketing", "auction.payment.tip.customHelper")
                : t("ticketing", "auction.payment.tip.helper")
              : undefined
          }
        >
          {suggestedTips &&
            suggestedTips.map((suggestedTip) => {
              return (
                <MenuItem key={suggestedTip.percentage} value={suggestedTip.percentage}>
                  <span data-test="tip-menu-item">
                    {suggestedTip.amount === 0 && currency && <>{formatCurrencyAmount(0, isoLocale, currency)}</>}
                    {(!suggestedTip.percentage || isSuggestedTipsAmount) && currency && suggestedTip.amount !== 0 && (
                      <>{formatCurrencyAmount(getDollarValue(suggestedTip.amount), isoLocale, currency)}</>
                    )}
                    {suggestedTip.percentage && currency && suggestedTip.amount !== 0 && !isSuggestedTipsAmount && (
                      <>{`${suggestedTip.percentage.toFixed(2)}%${
                        isAuction
                          ? ""
                          : ` (${formatCurrencyAmount(getDollarValue(suggestedTip.amount), isoLocale, currency)})`
                      }`}</>
                    )}
                  </span>
                </MenuItem>
              );
            })}
          <MenuItem key="free" value="free" data-test="free-tips-menu-item">
            {t("donationForm", "freeTips")}
          </MenuItem>
        </Select>
        {selectedTip.isFree && (
          <Box pt={2}>
            <TextField
              value={freeTips}
              label={t("donationForm", "tip")}
              type="number"
              onChange={handleChangeFreeTips}
              onBlur={logAmplitudeTipsChanged}
              endAdornment="$"
              inputProps={{
                type: "number",
                inputMode: "decimal",
                onWheel: (event: WheelEvent<HTMLInputElement>) => {
                  event.currentTarget.blur();
                },
              }}
              error={Boolean(errorOnNegativeTip)}
              helperText={errorOnNegativeTip && errorOnNegativeTip.message}
              className={classes.tipInput}
              inputRef={freeTipInput}
              data-test="manual-contribution-input"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
