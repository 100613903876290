import { ParsedUrlQuery } from "querystring";

import { FrontendRoutes, CampaignAction } from "@simplyk/common";
import router from "next/router";
import { DeepPartial } from "react-hook-form";

import { CreatedCampaigns } from "../components/PaymentProcessor/type";
import { CreatedCampaignsObject, FeatureFlagName, FeatureFlagTreatmentObject } from "../gql/gql-types";

import { getDonationFormFeatureFlagTreatment } from "./feature-flag";

export const formatCreatedCampaigns = (
  createdCampaignsResponse: DeepPartial<CreatedCampaignsObject> | undefined | null
): CreatedCampaigns | undefined => {
  if (!createdCampaignsResponse) {
    return undefined;
  }

  const individualCampaigns = createdCampaignsResponse.individualCampaigns;
  const teamObject = createdCampaignsResponse.team;

  const multipleCampaigns = !individualCampaigns
    ? false
    : (teamObject && individualCampaigns && individualCampaigns.length > 0) || individualCampaigns.length > 1;

  const hasTeam = Boolean(teamObject);
  const mainCampaign =
    teamObject ?? (individualCampaigns && individualCampaigns.length > 0 ? individualCampaigns?.[0] : undefined);

  const featureFlagTreatments =
    createdCampaignsResponse?.team?.featureFlagTreatments ||
    createdCampaignsResponse?.individualCampaigns?.[0]?.featureFlagTreatments;
  const isFundraiserFormEditDisabled = getDonationFormFeatureFlagTreatment(
    featureFlagTreatments as FeatureFlagTreatmentObject[],
    FeatureFlagName.IsFundraiserFormEditDisabled
  );

  return mainCampaign
    ? {
        id: mainCampaign.id as string,
        teamId: mainCampaign.teamId ?? undefined,
        color: mainCampaign.color as string,
        logoUrl: mainCampaign.logoUrl || "",
        campaignAction: hasTeam ? CampaignAction.CreateTeam : CampaignAction.IndividualFundraising,
        multipleCampaigns,
        isFundraiserFormEditDisabled,
        token: mainCampaign.token as string,
      }
    : undefined;
};

const navigateToPeerToPeerSuccess = ({
  formId,
  formPath,
  token,
  campaignAction,
  isFundraiserFormEditDisabled,
  color,
  organizationName,
  logoUrl,
  multipleCampaigns,
  query,
}: {
  formId: string;
  formPath: string | null | undefined;
  token: string | null;
  campaignAction: CampaignAction;
  isFundraiserFormEditDisabled: boolean;
  color: string;
  organizationName: string;
  logoUrl: string | undefined | null;
  multipleCampaigns: boolean;
  query?: ParsedUrlQuery;
}) => {
  router.push({
    pathname: FrontendRoutes.PeerToPeerSuccess,
    query: {
      ...query,
      formId,
      formPath,
      campaignAction,
      isFundraiserFormEditDisabled,
      color,
      organizationName,
      logoUrl: logoUrl || undefined,
      multipleCampaigns,
      token,
    },
  });
};

export default navigateToPeerToPeerSuccess;
