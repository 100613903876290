import { useContext } from "react";

import Grid from "@mui/material/Grid";
import { AmplitudeFlagKey, FormCategory, FormType } from "@simplyk/common";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useAmplitudeFeatureFlag } from "../../hooks/amplitude/useAmplitudeFeatureFlag";
import { useTranslate } from "../../hooks/useTranslate";
import { Button } from "../design-system/Button";
import { GiveWithHeartButton } from "../GiveWithHeartButton/GiveWithHeartButton";
import { DisplayWithInfoFormPreview } from "../InfoForm/InfoForm";

import { Lock } from "@/icons/outlined";

export const SubmitPaymentButton = ({
  handleFormSubmit,
}: {
  handleFormSubmit: (isExpressCheckout?: boolean) => (event?: React.SyntheticEvent) => Promise<void>;
}) => {
  const { t } = useTranslate();
  const { ticketing } = useContext(FrontendTicketingContext);
  const {
    isPreviewTemplateMode,
    isPreview,
    formType,
    isOnboarding,
    isSubmitting,
    isExpressCheckoutLoading,
    showProceedButton,
    formData: { organization: formOrg },
  } = useContext(FrontendFormContext);

  const previewSubmitButtonWording =
    formType === FormType.DonationForm
      ? t("donationForm", "preview.submitButton")
      : ticketing.formCategory === FormCategory.Lottery
        ? t("ticketing", "preview.submitButton.raffle")
        : t("ticketing", "preview.submitButton");

  const { isActive: isDonationFormCopyAbTest } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.DonationFormCopyAbTest,
    skip: formType !== FormType.DonationForm,
  });
  const { isActive: isTicketingFormButtonCopyAbTest } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.TicketingFormButtonCopyAbTest,
    skip: formType !== FormType.Ticketing,
  });

  const wording = (() => {
    if (formType === FormType.DonationForm) {
      return isDonationFormCopyAbTest ? t("common", "DonateNow") : t("common", "Donate");
    } else {
      return isTicketingFormButtonCopyAbTest ? t("common", "BuyNow") : t("common", "Confirm");
    }
  })();

  const isPaymentDisabled = formOrg?.isPaymentDisabled || false;

  return (
    <Grid item xs={12}>
      <DisplayWithInfoFormPreview content={previewSubmitButtonWording}>
        {formType === FormType.DonationForm && (
          <GiveWithHeartButton
            onSubmit={handleFormSubmit()}
            disabled={
              isOnboarding ||
              isSubmitting ||
              isExpressCheckoutLoading ||
              isPreviewTemplateMode ||
              isPreview ||
              isPaymentDisabled
            }
            cardIsFilled={true}
            isCardPaymentMethod={true}
            paymentInProgress={isSubmitting}
            showProceedMessage={showProceedButton}
            wording={wording}
          /> // todo: payment (cardIsFilled isCardPaymentMethod)
        )}
        {formType === FormType.Ticketing && (
          <Button
            variant="filled"
            data-test="donate"
            vibe="brand"
            size="large"
            onClick={handleFormSubmit()}
            disabled={
              isOnboarding ||
              isSubmitting ||
              isExpressCheckoutLoading ||
              isPreviewTemplateMode ||
              isPreview ||
              isPaymentDisabled
            }
            fullWidth
            isLoading={isSubmitting}
            endIcon={showProceedButton ? <Lock /> : undefined}
          >
            {showProceedButton ? t("common", "paymentMethod.button.proceedToPayment") : wording}
          </Button>
        )}
      </DisplayWithInfoFormPreview>
    </Grid>
  );
};
