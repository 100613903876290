import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    expressSeparator: {
      color: theme.palette.border.form.intense,
      textAlign: "center",
      fontWeight: 400,
      lineHeight: "16px",
    },
  }),
  { index: 10 }
);
