import { useContext } from "react";

import { ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE } from "@simplyk/common";
import { UseFormSetValue } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { captureSentryMessage } from "../../helpers/sentry";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";
import { useSubmitCommand } from "../PaymentProcessor/hooks/useSubmitCommand";
import { isPaymentFailed } from "../PaymentProcessor/type";

import { HandleErrorParams, unknownPaymentErrorCode } from "./helper";
import { HandlePostSubmit } from "./useStripePayment";

import { TicketingPaymentInput } from "@/types/ticketing";

export const useOfflinePayment = ({
  setValue,
  handleError,
  handlePostSubmit,
}: {
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  handleError: (params: HandleErrorParams) => void;
  handlePostSubmit: HandlePostSubmit;
}) => {
  const { handleSubmitCommand } = useSubmitCommand({ setValue });
  const { t } = useTranslate();
  const { commandId, formType, category } = useContext(FrontendFormContext);

  const payOffline = async (submitCommandInput: SubmitCommandFormInput) => {
    captureSentryMessage({
      message: "Start submit command",
      params: {
        commandId,
        formType,
        category: category ? category.toString() : "null",
      },
    });
    const submitResponse = await handleSubmitCommand(submitCommandInput);
    if (submitResponse && isPaymentFailed(submitResponse)) {
      handleError({
        message:
          submitResponse.error.code === ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            ? ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            : submitResponse.error.message,
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: submitResponse.error.code,
          message: submitResponse.error.message,
          isUnknown: false,
          paymentMethod: submitCommandInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const command = submitResponse.data?.command;
    if (!command) {
      handleError({
        message: t("common", "unknown_error", { code: unknownPaymentErrorCode.offline1.code }),
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: "missing_command",
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const { paymentInput } = submitCommandInput;
    const result = await handlePostSubmit({ paymentInput, command, formType });
    if (result.returnUrl) {
      return result.returnUrl;
    }

    if (result.error) {
      handleError({
        message: result.error,
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "unknown_error",
          message: result.error,
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    } else {
      handleError({
        message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.offline2.code }),
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "missing_return_url",
          message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.offline2.code }),
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    }
  };

  return { payOffline };
};
