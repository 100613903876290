import { Locales, convertCountryToCountryCode, OrganizationCountry } from "@simplyk/common";
import { inferRouterInputs } from "@trpc/server";

import { OrganizationObject } from "../gql/gql-types";

import { serializeFundraising } from "./customAnswer";
import { AppRouterType } from "./trpc";

import { DonationFormPaymentInput } from "@/types/donationForm";
import { TicketingPaymentInput } from "@/types/ticketing";

type RegistrationFundraisingInput = inferRouterInputs<AppRouterType>["form_submitCommand"]["createFundraisingPayload"];

export const getBillingDetails = (
  formData: TicketingPaymentInput | DonationFormPaymentInput,
  organization: OrganizationObject
) => {
  return {
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email,
    address: {
      line1: formData.address || "",
      line2: ".",
      city: formData.city || "",
      state: formData.region,
      postal_code: formData.postalCode || "",
      country: convertCountryToCountryCode(organization.country),
    },
  };
};

export const getFundraisingCreationInput = (
  command: { firstName?: string | null; lastName?: string | null; email?: string | null },
  formData: TicketingPaymentInput,
  locale: Locales,
  country: OrganizationCountry
): RegistrationFundraisingInput | undefined => {
  const { email, lastName, firstName } = command;
  const needsToCreateFundraising = email && lastName && firstName && (formData?.ticketsPurchased?.length || 0) > 0;

  if (!needsToCreateFundraising) {
    return undefined;
  }

  return serializeFundraising(formData.fundraising, { email, lastName, firstName }, locale, country);
};
