/* eslint-disable sonarjs/no-duplicate-string */
export const unknownPaymentErrorCode = {
  card1: { code: 901, description: "Stripe was not loaded when trying to pay: can't use stripe payment element" },
  card2: {
    code: 902,
    description: "Command was not returned from Zeffy server, payment is not succeeded, a dev can check",
  },
  card3: {
    code: 903,
    description: "Payment created but error getting return URL failed for fraudulent transaction",
  },
  card4: { code: 904, description: "Fail confirming payment on Stripe side, payment is not succeeded" },
  card5: {
    code: 905,
    description: "Payment intent on stripe is no longer valid and need a new payment method, payment is not succeeded",
  },
  card6: {
    code: 906,
    description: "Error when confirming payment on stripe: payment is not succeeded, a dev should take a look",
  },
  card7: { code: 907, description: "Unknown error: this error need to be looked at by dev" },
  card8: {
    code: 908,
    description: "Missing return URL: card payment is succeeded but thank you page could not be reached",
  },
  pad1: {
    code: 801,
    description: "Payment created but error getting return URL failed for fraudulent transaction",
  },
  pad2: {
    code: 802,
    description: "Command was not returned from Zeffy server, payment is not succeeded, a dev can check",
  },
  pad3: { code: 803, description: "Fail confirming payment on Stripe side, payment is not succeeded" },
  pad4: {
    code: 804,
    description: "Missing return URL: pad payment is succeeded but thank you page could not be reached",
  },
  cashApp1: {
    code: 701,
    description: "Command was not returned from Zeffy server, payment is not succeeded, a dev can check",
  },
  cashApp2: { code: 702, description: "Fail confirming payment on Stripe side, payment is not succeeded" },
  other1: { code: 201, description: "The payment error has no specific code" },
  other2: { code: 202, description: "The tip is negative: can't process it" },
  other3: { code: 203, description: "Unknown error on command submission on Zeffy side: a dev should take a look" },
  other4: {
    code: 204,
    description:
      "Error when creating registration campaigns. Payment is succeeded but will not display thank you page.",
  },
  other5: {
    code: 205,
    description: "Should not create registration campaigns. Payment is succeeded but will not display thank you page.",
  },
  offline1: {
    code: 601,
    description: "Command was not returned from Zeffy server, payment is not succeeded, a dev can check",
  },
  offline2: {
    code: 602,
    description: "Missing return URL: offline payment is succeeded but thank you page could not be reached",
  },
};

export interface HandleErrorParams {
  message?: string | null | undefined;
  commandId?: string | null;
  metadata?: Record<string, unknown>;
  isPaymentSucceeded?: boolean;
}
