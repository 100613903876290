/* eslint-disable sonarjs/no-duplicate-string */

import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { convertCountryCodeToCountry, PaymentMethod } from "@simplyk/common";
import { useElements } from "@stripe/react-stripe-js";
import {
  StripeExpressCheckoutElementClickEvent,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementReadyEvent,
} from "@stripe/stripe-js";
import { Control, FieldErrors, FieldValues, Path, UseFormSetValue, UseFormTrigger, useWatch } from "react-hook-form";
import { v4 } from "uuid";

import { AmplitudeEvents } from "../../constants/amplitude";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";

import { extractPaths, removeKeys } from "@/helpers/utils";
import { useCountryRegion } from "@/hooks/useCountryRegion";
import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { StripeContext } from "@/src/contexts/StripeContext";
import { CountryCode } from "@/src/gql/gql-types";
import { DonationFormPaymentInput } from "@/types/donationForm";
import { TicketingPaymentInput } from "@/types/ticketing";

type ExpressCheckoutProps<T extends FieldValues> = {
  handleFormSubmit: (isExpressCheckout: true) => (event?: React.SyntheticEvent) => Promise<void>;
  control: Control<T>;
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  trigger: UseFormTrigger<T>;
  errors: FieldErrors<T>;
};

const BILLING_FIELDS = ["email", "firstName", "lastName", "address", "postalCode", "city", "region", "country"];

const expressCheckoutRetryMax = 3;
const expressCheckoutRetryDelay = 400;

export const useExpressCheckout = <T extends FieldValues>({
  handleFormSubmit,
  setValue,
  trigger,
  errors,
  control,
}: ExpressCheckoutProps<T>) => {
  const { logAmplitudeEvent } = useAmplitude();
  const [clickEvent, setClickEvent] = useState<StripeExpressCheckoutElementClickEvent | undefined>();

  const elements = useElements();

  const { setExpressCheckoutWallet } = useContext(StripeContext);

  const {
    organization,
    setIsSubmitting,
    displayExpressCheckout,
    handleSetSupportsExpressCheckout,
    setSelectedPaymentMethod,
    defaultSelectedPaymentMethod,
  } = useContext(FrontendFormContext);

  const countryField = "country" as Path<T>;
  const country = useWatch({ control, name: countryField });
  const { currentCountryRegions } = useCountryRegion(country);

  // We use a key to force express checkout to re-render
  const [expressCheckoutKey, setExpressCheckoutKey] = useState(v4());

  // Loader for express checkout when loading/retrying
  const [showExpressCheckoutLoader, setShowExpressCheckoutLoader] = useState(true);

  // We use a ref to keep track of the number of retries
  const retryCount = useRef(0);

  // We use a ref to keep track of the timeout
  // To have only one timeout to cycle the express checkout key at a time
  const timeout = useRef<NodeJS.Timeout>(undefined);

  // Cycle the express checkout key to force the express checkout button to re-render
  const cycleExpressCheckoutKey = useCallback((timeoutDelay: number = expressCheckoutRetryDelay) => {
    // Clear the timeout if it exists
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    // Set a new timeout to cycle the express checkout key
    timeout.current = setTimeout(() => {
      setExpressCheckoutKey(v4());
    }, timeoutDelay);
  }, []);

  const resetExpressCheckoutRetries = useCallback(() => {
    retryCount.current = 0;
  }, []);

  // We skip the first cycle to avoid the express checkout key being cycled on the first render
  const isFirstCycle = useRef(true);
  // Cycle the express checkout key when the displayExpressCheckout state changes to true
  useEffect(() => {
    if (displayExpressCheckout) {
      if (!isFirstCycle.current) {
        cycleExpressCheckoutKey();
      }
      isFirstCycle.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayExpressCheckout]);

  // Retry logic for express checkout if it fails to load
  // We retry up to the set limit, increasing the delay by 100ms each time.
  const retryLoadingExpressCheckout = useCallback(() => {
    setShowExpressCheckoutLoader(true);
    retryCount.current++;
    cycleExpressCheckoutKey(expressCheckoutRetryDelay + retryCount.current * 100);
  }, [cycleExpressCheckoutKey]);

  const handleExpressCheckoutReady = useCallback(
    (event: StripeExpressCheckoutElementReadyEvent) => {
      setShowExpressCheckoutLoader(false);
      if (event.availablePaymentMethods && containsTrueValue(event.availablePaymentMethods)) {
        // Reset the retry count if express checkout is available
        resetExpressCheckoutRetries();
        handleSetSupportsExpressCheckout(true);
        //set payment method to card in preview mode even if expressCheckout is available
        setSelectedPaymentMethod(defaultSelectedPaymentMethod);
        elements?.getElement("payment")?.collapse();
        return;
      }

      if (retryCount.current < expressCheckoutRetryMax) {
        retryLoadingExpressCheckout();
        return;
      }

      // If we reach the retry limit, we log the event and stop retrying.
      logAmplitudeEvent(AmplitudeEvents.PaymentExpressCheckoutReloadFailed, { retries: retryCount.current });
      // If we reach the retry limit, we stop retrying and hide the express checkout section.
      handleSetSupportsExpressCheckout(false);
    },
    [
      logAmplitudeEvent,
      handleSetSupportsExpressCheckout,
      resetExpressCheckoutRetries,
      setSelectedPaymentMethod,
      defaultSelectedPaymentMethod,
      elements,
      retryLoadingExpressCheckout,
    ]
  );

  const handleExpressCheckoutClick = useCallback(
    (event: StripeExpressCheckoutElementClickEvent) => {
      trigger(undefined, { shouldFocus: true });
      setClickEvent(event);
      setSelectedPaymentMethod(PaymentMethod.ApplePayOrGooglePay);
      setExpressCheckoutWallet(event.expressPaymentType);
    },
    [setExpressCheckoutWallet, setSelectedPaymentMethod, trigger]
  );

  // Initially we would do all this directly in Express Checkout onClick handler, but since we need to trigger the form validation first,
  // we need to resort to this useEffect to make sure the errors object is up to date.
  useEffect(() => {
    if (!clickEvent) {
      return;
    }

    // We bypass billing info errors when submitting with express checkout as it will be handled by the express checkout modal.
    const errorsWithoutBillingInfo = removeKeys(errors ?? {}, BILLING_FIELDS);
    if (Object.keys(errorsWithoutBillingInfo).length > 0) {
      // We apply this logic to apple pay only because the view does not automatically scroll to the first error on iOS browsers.
      if (clickEvent.expressPaymentType === "apple_pay") {
        const keys = extractPaths(errors);
        const elements = keys.map((name) => document.getElementsByName(name)[0]).filter((el) => !!el);

        elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
        elements[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
        elements[0]?.focus({ preventScroll: true });
      }

      setClickEvent(undefined);
      return;
    }

    // This triggers the wallet modal to open. It should be called within 1 second of the click event.
    clickEvent.resolve({
      emailRequired: true,
      billingAddressRequired: true,
      business: { name: organization.name },
    });
    setClickEvent(undefined);
  }, [clickEvent, errors, organization.name]);

  const handleExpressCheckoutConfirm = useCallback(
    (event: StripeExpressCheckoutElementConfirmEvent) => {
      setIsSubmitting(true);
      const parts = event.billingDetails?.name?.trim().split(" ");
      const details = event.billingDetails;
      const firstName = parts?.[0] || details?.name || "N/A";
      const lastName = parts?.slice(1).join(" ") || "N/A";
      const email = details?.email;
      setValue("email", email);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("address", details?.address?.line1);
      setValue("postalCode", details?.address?.postal_code);
      setValue("city", details?.address?.city);
      const region = currentCountryRegions.find((region) => region.shortCode === details?.address.state)?.name;
      setValue("region", region || details?.address.state);
      setValue("country", convertCountryCodeToCountry(details?.address?.country as CountryCode));

      handleFormSubmit(true)();
    },
    [currentCountryRegions, handleFormSubmit, setIsSubmitting, setValue]
  );

  const handleExpressCheckoutLoadError = useCallback(() => {
    // Check if this triggers when not loaded to unhide.
    handleSetSupportsExpressCheckout(false);
    setShowExpressCheckoutLoader(false);
    setExpressCheckoutWallet(undefined);
  }, [setExpressCheckoutWallet, handleSetSupportsExpressCheckout]);

  return {
    expressCheckoutKey,
    showExpressCheckoutLoader,
    handleExpressCheckoutReady,
    handleExpressCheckoutClick,
    handleExpressCheckoutConfirm,
    handleExpressCheckoutLoadError,
  };
};

const containsTrueValue = (obj: Record<string, boolean>): boolean => {
  for (const key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
};
