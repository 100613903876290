import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import { isStripePaymentMethod, PAYMENT_METHOD_FROM_STRIPE, PaymentMethod } from "@simplyk/common";
import { PaymentElement as StripePaymentElement, useElements } from "@stripe/react-stripe-js";
import { LayoutObject, StripePaymentElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { StripeContext } from "../../contexts/StripeContext";
import { getBaseStripeAppearance, STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD } from "../../providers/StripeProvider";
import { Typography } from "../design-system/Typography";
import { usePaymentErrorContext } from "../PaymentElement/PaymentErrorContext";

import { PaymentMethodOption } from "./PaymentMethodOption";

interface PaymentMethodOptionsProps {
  offlinePaymentWording?: string | null;
}

export const PaymentMethodOptions: FC<PaymentMethodOptionsProps> = ({ offlinePaymentWording }) => {
  const theme = useTheme();
  const elements = useElements();

  const { setPaymentError } = usePaymentErrorContext();

  const [stripeElementsAreReady, setStripeElementsAreReady] = useState(false);

  const { hasPad, hasAch, stripePaymentMethodTypes, stripePaymentMethods, zeffyPaymentMethods } =
    useContext(StripeContext);
  const {
    organization,
    prioritizeBankPayment,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    chequeDescription,
    displayExpressCheckout,
    expressCheckoutIsSelected,
  } = useContext(FrontendFormContext);

  const hasStripePaymentMethods = stripePaymentMethods.length > 0;
  const showCustomAccordion = stripePaymentMethods.length === 1 && zeffyPaymentMethods.length > 0;

  useEffect(() => {
    if (!hasStripePaymentMethods) {
      // Remove loader when there are no stripe payment methods
      setStripeElementsAreReady(true);
    }
  }, [hasStripePaymentMethods]);

  const collapseStripePaymentElement = useCallback(() => {
    elements?.getElement("payment")?.collapse();
  }, [elements]);

  // To get arround the limitation of updating the stripe payment method
  // we need to update the payment method types to only include the bank account
  // and then restore the original payment method types
  const restoreStripePaymentMethods = useRef(false);
  useEffect(() => {
    if (prioritizeBankPayment) {
      if (hasAch) {
        elements?.update({ paymentMethodTypes: ["us_bank_account"] });
        restoreStripePaymentMethods.current = true;
      }

      if (hasPad) {
        setSelectedPaymentMethod(PaymentMethod.Pad);
        collapseStripePaymentElement();
      }
    } else {
      // Reset to Card when prioritizeBankPayment is false
      setSelectedPaymentMethod(PaymentMethod.Card);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prioritizeBankPayment]);

  // We should collapse the stripe payment elements when express checkout is selected
  useEffect(() => {
    if (displayExpressCheckout && expressCheckoutIsSelected) {
      elements?.getElement("payment")?.collapse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayExpressCheckout, expressCheckoutIsSelected]);

  const displayPadOnTop = prioritizeBankPayment && hasPad;
  useEffect(() => {
    if (stripePaymentMethods.length > 0 && zeffyPaymentMethods.length > 0) {
      const baseStripeAppearance = getBaseStripeAppearance(theme);
      elements?.update({
        appearance: {
          ...baseStripeAppearance,
          rules: {
            ...baseStripeAppearance.rules,

            ".AccordionItem": {
              ...(displayPadOnTop ? { borderTopLeftRadius: "0px" } : undefined),
              ...(displayPadOnTop ? { borderTopRightRadius: "0px" } : undefined),
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
              boxShadow: "none",
            },

            ".AccordionItem:hover": {
              color: theme.palette.text.brand.moderate,
            },
          },
        },
      });
    }
  }, [
    displayPadOnTop,
    elements,
    selectedPaymentMethod,
    stripePaymentMethods,
    stripePaymentMethods.length,
    theme,
    theme.palette.text.brand.moderate,
    zeffyPaymentMethods,
    zeffyPaymentMethods.length,
  ]);

  const handleZeffyPaymentMethodChange = useCallback(
    (value: PaymentMethod) => {
      setPaymentError(null);
      collapseStripePaymentElement();
      setSelectedPaymentMethod(value);
    },
    [collapseStripePaymentElement, setPaymentError, setSelectedPaymentMethod]
  );

  const handleStripePaymentMethodChange = useCallback(
    (event: StripePaymentElementChangeEvent) => {
      // Ensure that only Stripe methods are handled here
      if (!isStripePaymentMethod(event.value.type)) {
        return;
      }

      // Avoid preselecting card when initially loading the form
      // + ignore additional events emitted by "handleZeffyPaymentMethodChange -> collapseStripePaymentElement" that select the card method while we want to change the payment method
      if (event.collapsed) {
        return;
      }

      setPaymentError(null);
      setSelectedPaymentMethod(PAYMENT_METHOD_FROM_STRIPE[event.value.type], event.complete);
    },
    [setPaymentError, setSelectedPaymentMethod]
  );

  const handleStripeAccordionClick = useCallback(
    (value: PaymentMethod) => {
      if (value !== selectedPaymentMethod) {
        setPaymentError(null);
      }
      setSelectedPaymentMethod(value);
    },
    [selectedPaymentMethod, setPaymentError, setSelectedPaymentMethod]
  );

  const paymentMethodOptions: StripePaymentElementOptions = useMemo(
    () => ({
      business: { name: organization.name },
      fields: {
        billingDetails: {
          name: "never",
          email: "never",
          address: "never",
        },
      },
      wallets: { googlePay: "never", applePay: "never" },
      layout: {
        type: showCustomAccordion ? "tabs" : "accordion",
        defaultCollapsed: displayExpressCheckout,
        ...(!showCustomAccordion && { radios: true }), // radios is only available for accordion layout
      } as LayoutObject,
      paymentMethodOrder: prioritizeBankPayment
        ? [STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD.ach, STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD.card]
        : [STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD.card, STRIPE_PAYMENT_METHOD_FROM_PAYMENT_METHOD.ach],
    }),
    [organization.name, showCustomAccordion, displayExpressCheckout, prioritizeBankPayment]
  );

  const onLoaderStart = useCallback(() => {
    setStripeElementsAreReady(false);
  }, []);

  const onReady = useCallback(() => {
    setStripeElementsAreReady(true);
    // Once the stripe elements are ready, we can restore the original payment method types after a short delay
    if (restoreStripePaymentMethods.current) {
      restoreStripePaymentMethods.current = false;
      setTimeout(() => {
        elements?.update({ paymentMethodTypes: stripePaymentMethodTypes });
      }, 600);
    }
  }, [elements, stripePaymentMethodTypes]);

  const stripePaymentElements = hasStripePaymentMethods && (
    <StripePaymentElement
      key={prioritizeBankPayment ? "prioritizeBankPayment" : "cardFirst"}
      id="payment-element"
      // When have a handler on our custom accordion, so this one becomes redundant
      onChange={showCustomAccordion ? undefined : handleStripePaymentMethodChange}
      options={paymentMethodOptions}
      onLoaderStart={onLoaderStart}
      onReady={onReady}
    />
  );

  const filteredZeffyPayMethods = useMemo(() => {
    if (displayPadOnTop) {
      return zeffyPaymentMethods.filter((method) => method !== PaymentMethod.Pad);
    }
    return zeffyPaymentMethods;
  }, [displayPadOnTop, zeffyPaymentMethods]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      data-test="payment-elements-wrapper"
    >
      {displayPadOnTop && (
        <PaymentMethodOption
          isFirst
          isLast={!hasStripePaymentMethods && filteredZeffyPayMethods.length === 0}
          loading={!stripeElementsAreReady}
          onSelect={handleZeffyPaymentMethodChange}
          selected={selectedPaymentMethod === PaymentMethod.Pad}
          paymentMethod={PaymentMethod.Pad}
          offlinePaymentWording={offlinePaymentWording}
          hasBorderBottom={!hasStripePaymentMethods && filteredZeffyPayMethods.length === 0}
          data-test={PaymentMethod.Pad.toLowerCase()}
        />
      )}
      {showCustomAccordion ? (
        <Box>
          <PaymentMethodOption
            isFirst={!displayPadOnTop}
            isLast={filteredZeffyPayMethods.length === 0}
            onSelect={handleStripeAccordionClick}
            selected={stripePaymentMethods[0] === selectedPaymentMethod}
            paymentMethod={stripePaymentMethods[0]}
            data-test={stripePaymentMethods[0].toLowerCase()}
            hasBorderBottom={false}
            offlinePaymentWording={offlinePaymentWording}
          />
          <Collapse in={stripePaymentMethods[0] === selectedPaymentMethod} collapsedSize={0}>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.constants.isFormV2 ? theme.palette.surface.form.supershy : "transparent",
                border: theme.constants.isFormV2
                  ? `1px solid ${theme.palette.border.form.moderate}`
                  : "1px solid #e6e6e6",
                borderTop: "none",
                ...(filteredZeffyPayMethods.length > 0 && {
                  borderBottom: "none",
                }),
                padding: theme.spacing(0.5, 2, 4),
              })}
            >
              {stripePaymentElements}
            </Box>
          </Collapse>
        </Box>
      ) : (
        stripePaymentElements
      )}

      {filteredZeffyPayMethods.length > 0 && (
        <Box
          sx={{
            ...(stripePaymentMethods.length > 1 && {
              marginTop: "-5px",
            }),
          }}
        >
          {filteredZeffyPayMethods.map((paymentMethod, index) => (
            <PaymentMethodOption
              key={paymentMethod}
              isFirst={!stripePaymentMethods.length && !displayPadOnTop}
              isLast={index + 1 === filteredZeffyPayMethods.length}
              loading={!stripeElementsAreReady}
              onSelect={handleZeffyPaymentMethodChange}
              selected={selectedPaymentMethod === paymentMethod}
              paymentMethod={paymentMethod}
              data-test={paymentMethod.toLowerCase()}
              offlinePaymentWording={offlinePaymentWording}
            >
              {paymentMethod === PaymentMethod.Cheque && (
                <Box
                  sx={(theme) => ({
                    display: selectedPaymentMethod === paymentMethod ? "block" : "none",
                    backgroundColor: theme.constants.isFormV2
                      ? theme.palette.surface.form.quiet
                      : theme.palette.neutral[95],
                    color: theme.constants.isFormV2
                      ? theme.palette.text.form.intense
                      : theme.palette.text.neutral.moderate,
                    padding: theme.spacing(1, 2),
                    marginTop: theme.spacing(2),
                    borderRadius: 1,
                    whiteSpace: "pre-wrap",
                  })}
                >
                  <Typography variant="body2">{chequeDescription}</Typography>
                </Box>
              )}
            </PaymentMethodOption>
          ))}
        </Box>
      )}
    </Box>
  );
};
