import { useContext, useState, useCallback } from "react";

import { Grid, ThemeProvider } from "@mui/material";
import { Locales, OrganizationCountry } from "@simplyk/common";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { zeffyTheme } from "../../theme/theme";
import { ConfirmPaymentBubbleTooltip } from "../ConfirmPaymentBubbleTooltip/ConfirmPaymentBubbleTooltip";
import { Typography } from "../design-system/Typography";
import Image from "../Image/Image";
import { TipInput } from "../TipInput/TipInput";

import { useZeffyDescription } from "./hooks/useZeffyDescription";
import { ZeffyLearnMore } from "./ZeffyLearnMore";

export const PaymentProcessorTipInformation = () => {
  const { isSmallScreen } = useMediaQuery();
  const { t } = useTranslate();
  const { organization } = useContext(FrontendFormContext);
  const { locale } = useLocaleContext();
  const { showZeffyDescription, openZeffyDescription, closeZeffyDescription } = useZeffyDescription();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleOpenTooltip = useCallback(() => setTooltipOpen(true), []);
  const handleCloseTooltip = useCallback(() => setTooltipOpen(false), []);

  return (
    <Grid
      item
      container
      xs={12}
      data-test="tip-section"
      id="tip-section-input"
      sx={(theme) => ({
        marginTop: {
          xs: 0,
          md: theme.spacing(1),
        },
      })}
    >
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          margin: theme.spacing(1, 0),
        })}
      >
        <Grid container>
          <Grid item xs={12}>
            <>
              {organization.country === OrganizationCountry.Canada ? (
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    color: theme.constants.isFormV2 ? theme.palette.text.form.moderate : undefined,
                  })}
                >
                  <>
                    {t("donationForm", "thanksToIncludesTipsQuebec")}
                    {locale === Locales.FR && (
                      <Image src="/assets/icons/quebec-flag.svg" alt="quebec flag" height={14} width={26} />
                    )}
                  </>
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={(theme) => ({
                      color: theme.constants.isFormV2 ? theme.palette.text.form.intense : theme.palette.text.primary,
                    })}
                  >
                    {t("donationForm", "contributionToZeffy", {
                      orgName: organization.name,
                    })}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={(theme) => ({
                      color: theme.constants.isFormV2 ? theme.palette.grey[600] : theme.palette.grey[500],
                    })}
                  >
                    ({t("donationForm", "optional")})
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({
                      marginTop: theme.spacing(1),
                      color: theme.constants.isFormV2 ? theme.palette.text.form.moderate : theme.palette.text.secondary,
                    })}
                  >
                    {t("donationForm", "zeffyCoversPaymentFees", { orgName: organization.name })}
                  </Typography>
                </>
              )}
            </>
          </Grid>
          {isSmallScreen && (
            <Grid item>
              <Typography
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                  cursor: "pointer",
                  color: theme.constants.isFormV2 ? theme.palette.text.form.supershy : theme.palette.primary.main,
                })}
                variant="body2"
                onClick={openZeffyDescription}
              >
                {t("donationForm", "knowMore")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isSmallScreen && (
        <Grid item xs>
          <Typography
            sx={(theme) => ({
              color: theme.constants.isFormV2 ? theme.palette.text.form.supershy : theme.palette.primary.main,
              cursor: "pointer",
            })}
            variant="body2"
            onClick={openZeffyDescription}
            width="fit-content"
          >
            {t("donationForm", "knowMore")}
          </Typography>
        </Grid>
      )}
      <ConfirmPaymentBubbleTooltip
        hidden={!isSmallScreen}
        forceOpen={tooltipOpen}
        offset={zeffyTheme.legacySpacing(12)}
      >
        <Grid item xs={isSmallScreen ? 12 : undefined} sx={{ alignContent: "flex-end" }}>
          <Grid container display="flex" justifyContent="flex-end">
            <Grid item xs={isSmallScreen ? 8 : undefined}>
              <TipInput onOpen={handleOpenTooltip} onClose={handleCloseTooltip} />
            </Grid>
          </Grid>
        </Grid>
      </ConfirmPaymentBubbleTooltip>
      <ThemeProvider theme={zeffyTheme}>
        <ZeffyLearnMore open={showZeffyDescription} onClose={closeZeffyDescription} />
      </ThemeProvider>
    </Grid>
  );
};
