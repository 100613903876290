import { useContext } from "react";

import { Stack, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FormType, formatSmartNarrowCurrencyFromCents } from "@simplyk/common";
import { Control } from "react-hook-form";

import { useFrontendAuctionContext } from "../../contexts/FrontendAuctionContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { InfoIconWithTooltip } from "../../features/LiveFormEditor/Steps/components/InfoIconWithTooltip";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { ConfirmPaymentBubbleTooltip } from "../ConfirmPaymentBubbleTooltip/ConfirmPaymentBubbleTooltip";
import { Divider } from "../design-system/Divider/Divider";
import { InformationTooltip } from "../InformationTooltip/InformationTooltip";

import { PaymentProcessorTicketSummary } from "./PaymentProcessorTicketSummary";
import { PaymentProcessorTipInformation } from "./PaymentProcessorTipInformation";
import { PaymentProcessorTotalWithTip } from "./PaymentProcessorTotalWithTip";

import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { TicketingPaymentInput } from "@/types/ticketing";

export interface PaymentProcessorSummaryProps {
  control?: Control<TicketingPaymentInput>;
}

export const PaymentProcessorSummary = ({ control }: PaymentProcessorSummaryProps) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const isFormV2 = theme.constants.isFormV2;

  const { isSmallScreen } = useMediaQuery();
  const { isoLocale } = useLocaleContext();
  const { hasAlreadyBid } = useFrontendAuctionContext();
  const { displayedFormAmount, formType, currency, displayTipSelection, isAuction, stripeRecurrenceInterval } =
    useContext(FrontendFormContext);

  const isMonthlyDonation = formType === FormType.DonationForm && stripeRecurrenceInterval === "month";
  const isYearlyDonation = formType === FormType.DonationForm && stripeRecurrenceInterval === "year";

  const getMainTitle = () => {
    if (isAuction) {
      return t("ticketing", "totalBids");
    }
    if (formType === FormType.Ticketing) {
      return t("ticketing", "order");
    }

    if (isMonthlyDonation) {
      return t("donationForm", "monthlyDonationCancelAnytime");
    }
    if (isYearlyDonation) {
      return t("donationForm", "yearlyDonationCancelAnytime");
    }
    return t("donationForm", "donation");
  };

  return (
    <ConfirmPaymentBubbleTooltip hidden={isSmallScreen}>
      <Grid
        container
        sx={{
          position: "relative",
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: "solid",
          overflow: "hidden",
          borderColor: isFormV2 ? theme.palette.border.form.moderate : theme.palette.neutral[80],
          backgroundColor: isFormV2 ? theme.palette.surface.form.supershy : undefined,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            marginTop: 1,
            marginBottom: {
              xs: 1,
              md: 2,
            },
            padding: 1,
          }}
        >
          <Grid container sx={{ paddingRight: 2, paddingLeft: 2 }}>
            <Grid item xs>
              {!hasAlreadyBid && (
                <Typography
                  variant="subtitle1"
                  sx={{ color: isFormV2 ? theme.palette.text.form.intense : "neutral.20" }}
                  data-test="payment-processor-summary-title"
                >
                  {getMainTitle()}
                </Typography>
              )}
              {hasAlreadyBid &&
                (isFormV2 ? (
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: theme.palette.text.form.intense, marginRight: 0.5 }}
                    >
                      {t("ticketing", "activeBids")}
                    </Typography>

                    <InfoIconWithTooltip
                      tooltip={t("ticketing", "activeBidsTooltip")}
                      iconProps={{ size: "small", vibe: "text-form-intense" }}
                    />
                  </Stack>
                ) : (
                  <>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: isFormV2 ? theme.palette.text.form.intense : "neutral.20", marginRight: 0.5 }}
                    >
                      {t("ticketing", "activeBids")}
                    </Typography>

                    <InformationTooltip title={t("ticketing", "activeBidsTooltip")} alignOnText />
                  </>
                ))}
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                align="right"
                sx={{
                  color: theme.constants.isFormV2 ? theme.palette.text.form.intense : theme.palette.text.primary,
                }}
                data-test="payment-processor-summary-amount"
              >
                {currency && formatSmartNarrowCurrencyFromCents(displayedFormAmount, { locale: isoLocale, currency })}
                {isMonthlyDonation && ` / ${t("dashboard", "common.month")}`}
                {isYearlyDonation && ` / ${t("dashboard", "common.year")}`}
              </Typography>
            </Grid>

            {formType === FormType.Ticketing && control && (
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Grid container spacing={2}>
                  <PaymentProcessorTicketSummary control={control} />
                </Grid>
              </Grid>
            )}

            {displayTipSelection && (
              <>
                <Grid item xs={12} sx={{ marginTop: 3, marginBottom: 2 }}>
                  <Divider sx={isFormV2 ? { borderColor: `${theme.palette.border.form.moderate} !important` } : {}} />
                </Grid>
                <PaymentProcessorTipInformation />
              </>
            )}
          </Grid>
        </Grid>
        {displayTipSelection && <PaymentProcessorTotalWithTip />}
      </Grid>
    </ConfirmPaymentBubbleTooltip>
  );
};
