/* eslint-disable sonarjs/no-duplicate-string */
import { useCallback, useContext } from "react";

import { FieldValues } from "react-hook-form";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { isDonationForm } from "../../helpers/form";
import { getErrorMessages } from "../../helpers/reactHookForm";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { PaymentElementProps } from "../PaymentProcessor/type";

import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { useLocaleContext } from "@/src/contexts/LocaleContext";
import { StripeContext } from "@/src/contexts/StripeContext";

type LogDonorFormSubmittedParams = {
  isExpressCheckout: boolean;
  hasSucceeded: boolean;
  formErrors?: string[];
  extra?: Record<string, unknown>;
};

export function useAmplitudePaymentEventLog<T extends FieldValues>({
  errors,
  formObject,
}: Pick<PaymentElementProps<T>, "errors" | "formObject">) {
  const { logAmplitudeEvent } = useAmplitude();
  const { allowStripeLink, expressCheckoutWallet } = useContext(StripeContext);

  const { ticketing } = useContext(FrontendTicketingContext);

  const {
    commandId,
    organization,
    formType,
    tip,
    selectedPaymentMethod,
    isEmbed,
    stripeRecurrenceInterval,
    currency,
    category,
    formData,
    prioritizeBankPayment,
    expressCheckoutDisabled,
    paymentMode,
    selectedTip,
    formAmplitudeContext,
    displayedFormAmount,
    getTipChoice,
  } = useContext(FrontendFormContext);

  const { locale, isoLocale } = useLocaleContext();

  const logDonorFormSubmitted = useCallback(
    ({ isExpressCheckout, formErrors, extra, hasSucceeded }: LogDonorFormSubmittedParams) => {
      logAmplitudeEvent(AmplitudeEvents.DonorFormSubmitted, {
        commandId,
        formType: category,
        errors: getErrorMessages({
          keys: Object.keys(errors),
          errors,
        }).concat(formErrors || []),
        hasSucceeded,
        countCustomQuestions: formObject.questions?.length,
        displayAddressQuestion: formObject.displayAddressQuestion,
        encourageMonthlyDonation: isDonationForm(formData) && Boolean(formData.encourageMonthlyDonations),
        hasALinkedTicketing: isDonationForm(formData) && Boolean(formData.redirectUrl),
        hasAdditionalDonation: Boolean(ticketing.extraDonation),
        hasBanner: Boolean(formObject.bannerUrl),
        hasBannerVideo: Boolean(formObject.bannerVideoUrl),
        hasCheckOption: formObject.allowCheque,
        hasInHonourOption: isDonationForm(formData) && Boolean(formData.allowInHonour),
        hasLogo: Boolean(formObject.logoUrl),
        hasNoSuggestedAmount: formAmplitudeContext.hasNoSuggestedAmount || null,
        hasReceipt: isDonationForm(formData) && formData.hasReceipt,
        hasReceiptMessage: formAmplitudeContext.hasReceiptMessage || null,
        hasTarget: Boolean(ticketing.thermometerTarget),
        isEmbed,
        isPrimaryCampaign: isDonationForm(formData) && formData.isPrimaryCampaign,
        isPrimaryTeam: isDonationForm(formData) && formData.isPrimaryTeam,
        isoLocale,
        locale,
        organizationCountry: organization?.country,
        tipAmount: tip / 100,
        tipChoice: selectedTip.percentage ? getTipChoice(selectedTip.percentage.toString()) : "Custom",
        tipPercentage: displayedFormAmount > 0 ? ((tip / displayedFormAmount) * 100).toFixed(2) : "N/A",
        formId: formObject.id,
        amount: displayedFormAmount / 100,
        totalAmount: (displayedFormAmount + tip) / 100,
        paymentMethod: selectedPaymentMethod,
        isExpressCheckout,
        currency,
        allowStripeLink,
        expressCheckoutWallet: isExpressCheckout ? expressCheckoutWallet : null,
        ...extra,
      });
    },
    [
      logAmplitudeEvent,
      commandId,
      category,
      errors,
      formObject.questions?.length,
      formObject.displayAddressQuestion,
      formObject.bannerUrl,
      formObject.bannerVideoUrl,
      formObject.allowCheque,
      formObject.logoUrl,
      formObject.id,
      formData,
      ticketing.extraDonation,
      ticketing.thermometerTarget,
      formAmplitudeContext.hasNoSuggestedAmount,
      formAmplitudeContext.hasReceiptMessage,
      isEmbed,
      isoLocale,
      locale,
      organization?.country,
      tip,
      selectedTip.percentage,
      getTipChoice,
      displayedFormAmount,
      selectedPaymentMethod,
      currency,
      allowStripeLink,
      expressCheckoutWallet,
    ]
  );

  const logFormClickSubmitPaymentError = useCallback(
    (metadata?: Record<string, unknown>) => {
      logAmplitudeEvent(AmplitudeEvents.FormClickSubmitPaymentError, {
        ...metadata,
        country: organization?.country,
        paymentMethod: selectedPaymentMethod,
        commandId,
        expressCheckoutDisabled,
        stripeRecurrenceInterval,
        tip,
        isEmbed,
        formType,
        formCategory: category,
        currency,
        category,
        prioritizeBankPayment,
        paymentMode,
        allowStripeLink,
        expressCheckoutWallet,
      });
    },
    [
      logAmplitudeEvent,
      organization?.country,
      selectedPaymentMethod,
      commandId,
      expressCheckoutDisabled,
      stripeRecurrenceInterval,
      tip,
      isEmbed,
      formType,
      category,
      currency,
      prioritizeBankPayment,
      paymentMode,
      allowStripeLink,
      expressCheckoutWallet,
    ]
  );

  return {
    logDonorFormSubmitted,
    logFormClickSubmitPaymentError,
  };
}
