import { PaymentMethod, PaymentMethodWithStripe } from "@simplyk/common";
import { loadStripe, StripeConstructorOptions, StripeElementsOptionsMode } from "@stripe/stripe-js";

import { Locales, OrganizationCountry, PaymentMethod as GqlPaymentMethod } from "../gql/gql-types";

const getStripeApiKey = (country: OrganizationCountry): string => {
  if (country === OrganizationCountry.Canada) {
    return process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_CA as string;
  }
  return process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY_USA as string;
};

export const getStripeInstance = async (country: OrganizationCountry, locale: Locales) => {
  const stripePublicApiKey = getStripeApiKey(country);
  return loadStripe(stripePublicApiKey, {
    locale: locale.toLowerCase() as StripeConstructorOptions["locale"],
  });
};

export const serializeLocale = (locale: Locales) => {
  return String(locale).toLowerCase() as StripeConstructorOptions["locale"];
};

export const convertToGqlPaymentMethod = (stripePaymentMethod: PaymentMethodWithStripe) => {
  if (PaymentMethod.ApplePayOrGooglePay === stripePaymentMethod) {
    return GqlPaymentMethod.ApplePayOrGooglePay;
  }
  if (PaymentMethod.Card === stripePaymentMethod) {
    return GqlPaymentMethod.Card;
  }
  if (PaymentMethod.Ach === stripePaymentMethod) {
    return GqlPaymentMethod.Ach;
  }
  if (PaymentMethod.Pad === stripePaymentMethod) {
    return GqlPaymentMethod.Pad;
  }
  if (PaymentMethod.CashApp === stripePaymentMethod) {
    return GqlPaymentMethod.CashApp;
  }

  throw new Error(`Unknown payment method: ${stripePaymentMethod}`);
};

export type PaymentMode = NonNullable<StripeElementsOptionsMode["mode"]>;
