import { useCallback, useContext } from "react";

import { RecaptchaGuardMessage, TicketingFormCategory } from "@simplyk/common";
import { useSnackbar } from "notistack";
import { FieldValues, UseFormSetValue } from "react-hook-form";

import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { useUserInputContext } from "../../../contexts/UserInputContext";
import { RecaptchaAction } from "../../../enums/recaptcha";
import { useIsFormV2 } from "../../../features/FormV2/hooks/useIsFormV2";
import { trpc } from "../../../helpers/trpc";
import { useRecaptchaRetry } from "../../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../../hooks/useTranslate";
import { unknownPaymentErrorCode } from "../../PaymentElement/helper";
import { formatSubmitCommandData, SubmitCommandFormInput } from "../helper";
import { PaymentElementProps, PaymentSucceeded } from "../type";

import { TicketingPaymentInput } from "@/types/ticketing";

export interface PaymentFailed {
  error: { message: string; code: string };
}

export type SubmitCommandResponse = PaymentSucceeded | PaymentFailed;

export const useSubmitCommand = <T extends FieldValues>({ setValue }: Pick<PaymentElementProps<T>, "setValue">) => {
  const { mutateAsync: submitCommandMutation } = trpc.form_submitCommand.useMutation();
  const { mutateAsync: logCommandUserInteractionMutation } = trpc.form_logCommandUserInteraction.useMutation();

  const { displayedFormAmount, stripeRecurrenceInterval, commandId, organization, category } =
    useContext(FrontendFormContext);

  const { userInputs } = useUserInputContext();

  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { executeTrpc } = useRecaptchaRetry();
  const isFormV2 = useIsFormV2();
  const { fallBackToTicketingContainerOnFullTicketing, fallBackToTicketingContainerOnNoTicketSelected } =
    useContext(FrontendTicketingContext);

  const handleSubmitCommand = async (data: SubmitCommandFormInput): Promise<SubmitCommandResponse> => {
    if (!organization) {
      return { error: { message: t("common", "unknown_error"), code: "NoOrganizationError" } };
    }

    const params = formatSubmitCommandData(data, { displayedFormAmount, stripeRecurrenceInterval, isFormV2 });
    const action = RecaptchaAction.SubmitCommand;
    const email = params.email;

    if (params.productBids) {
      await logCommandUserInteractionMutation({ commandId, payload: userInputs });
    }

    const response = await executeTrpc(action, email, submitCommandMutation, {
      ...params,
      id: commandId,
    });

    const error = response.error;
    const responseData = await response.data;

    if (error?.message === RecaptchaGuardMessage) {
      return { error: { message: t("common", "recaptcha_error"), code: "RecaptchaFailed" } };
    }

    if (error || !responseData) {
      const code = error?.code || "unknown_error";
      const isHandled = error?.message === "isHandled";

      const displayedMessage =
        code === "confirm_payment_intent_failed"
          ? error?.message // We use the stripe error message directly for card errors
          : code === "missing_required_answers" || isHandled
            ? t("common", code as never)
            : t("common", "unknown_error", { code: unknownPaymentErrorCode.other3.code });
      handleFallbackOnPaymentError(error);
      return {
        error: {
          code,
          message: displayedMessage || t("common", "unknown_error", { code: unknownPaymentErrorCode.other3.code }),
        },
      };
    }
    return { data: responseData, paymentInput: data.paymentInput };
  };

  const handleFallbackOnPaymentError = useCallback(
    (error: { code?: string | null } | undefined | null) => {
      if (error) {
        if (error.code === "ticketing_is_full") {
          enqueueSnackbar(t("ticketing", "errorOnFullTicketing"), { vibe: "danger" });
          fallBackToTicketingContainerOnFullTicketing(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
        if (error.code === "ticketing_is_closed") {
          const isAuction = category === TicketingFormCategory.Auction;
          enqueueSnackbar(t("ticketing", isAuction ? "errorOnClosedAuction" : "errorOnClosedOccurrence"), {
            vibe: "danger",
          });
          fallBackToTicketingContainerOnNoTicketSelected(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
        if (error.code === "full_choice_errors") {
          enqueueSnackbar(t("ticketing", "errorOnFullChoice"), { vibe: "danger" });
          fallBackToTicketingContainerOnNoTicketSelected(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
        if (error.code === "expired_early_bird") {
          enqueueSnackbar(t("ticketing", "errorOnExpiredEarlyBirdRate"), { vibe: "danger" });
          fallBackToTicketingContainerOnNoTicketSelected(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
        if (error.code === "archived_rate_selected") {
          enqueueSnackbar(t("ticketing", "errorOnArchivedRateSelected"), { vibe: "danger" });
          fallBackToTicketingContainerOnNoTicketSelected(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
        if (error.code === "form_deleted") {
          enqueueSnackbar(t("ticketing", "errorOnFormDeleted"), { vibe: "danger" });
          fallBackToTicketingContainerOnNoTicketSelected(setValue as UseFormSetValue<TicketingPaymentInput>);
          return;
        }
      }
    },
    [
      category,
      enqueueSnackbar,
      fallBackToTicketingContainerOnFullTicketing,
      fallBackToTicketingContainerOnNoTicketSelected,
      setValue,
      t,
    ]
  );

  return { handleSubmitCommand };
};
