// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericObject = Record<string, unknown>;

const isSpecialObject = (obj: GenericObject): boolean => {
  return typeof obj === "object" && obj !== null && "type" in obj && "message" in obj;
};

export const shuffle = <T>(array: Array<T>) => {
  let currentIndex = array.length;
  let randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const shouldDuplicateLogo = (
  logoIsDeleted: boolean,
  formLogo: string | undefined,
  defaultFormLogo: string | undefined
) => {
  return Boolean(!logoIsDeleted && !formLogo && defaultFormLogo);
};

export const scrollToTop = (behavior: ScrollBehavior = "smooth") => {
  window.scrollTo({ top: 0, behavior });
};

// Given: {"amount":{"type":"required","message":"Amount is required","ref":{}},"answers":[{"answers":[{"type":"required","message":"Answer is required","ref":{}}]}]}
// extractPaths generates paths excluding deep traversal of 'type' and 'message' marked objects.
// E.g., ["amount", "answers.0.answers"]
export const extractPaths = (obj: GenericObject, currentPath: string = ""): string[] => {
  let paths: string[] = [];

  if (isSpecialObject(obj)) {
    // If the object itself is a 'special object', we should return the current path
    // But since we're at the root in this call, there's no path to add
    if (currentPath !== "") {
      return [currentPath];
    }
  } else if (typeof obj === "object" && obj !== null) {
    Object.entries(obj).forEach(([key, value]) => {
      const newPath = `${currentPath}${currentPath ? "." : ""}${key}`;
      if (Array.isArray(value)) {
        (value as Array<unknown>).forEach((item, index) => {
          const arrayPath = `${newPath}.${index}`;
          paths = paths.concat(extractPaths(item as GenericObject, arrayPath));
        });
      } else {
        paths = paths.concat(extractPaths(value as GenericObject, newPath));
      }
    });
  }

  return paths;
};

export const removeKeys = (obj: GenericObject, keysToRemove: string[]): GenericObject => {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => delete newObj[key]);
  return newObj;
};
