const startTimeOnSite = new Date();

const getDurationTimeOnPage = () => {
  return new Date().getTime() - startTimeOnSite.getTime();
};

export const useDurationOnPage = () => {
  return {
    startTimeOnSite,
    getDurationTimeOnPage,
  };
};
