import { FunctionComponent, memo, PropsWithChildren, useContext } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme, ThemeProvider, useTheme } from "@mui/material/styles";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { zeffyTheme } from "../../theme/theme";
import { getTransitions } from "../design-system/css";
import { Tooltip } from "../design-system/Tooltip/Tooltip";
import { Typography } from "../design-system/Typography";

import { ArrowRight as RightArrowIcon } from "@/icons/outlined";

const PREFIX = "InfoForm";

const classes = {
  container: `${PREFIX}-container`,
  reverseIntense: `${PREFIX}-reverseIntense`,
  hoveredContainer: `${PREFIX}-hoveredContainer`,
};

const StyledGrid = memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&.${classes.container}`]: {
      borderRadius: theme.spacing(1),
      backgroundColor: zeffyTheme.palette.surface.brand.intense,
      ...getTransitions("opacity 100ms ease-out", "opacity"),
    },

    [`& .${classes.reverseIntense}`]: {
      fill: theme.palette.text.reverse.intense,
    },

    [`&.${classes.hoveredContainer}`]: {
      "&:hover": {
        [`& .${classes.container}`]: { opacity: 1, ...getTransitions("opacity 100ms ease-out", "opacity") },
      },
    },
  }))
);

interface InfoFormProps {
  content: React.ReactNode;
}

export const InfoForm = ({ content }: InfoFormProps) => {
  return (
    <StyledGrid container>
      <Grid item xs={1} paddingBottom={0.5} paddingTop={0.5} marginRight={1.25}>
        <RightArrowIcon className={classes.reverseIntense} />
      </Grid>
      <Grid item xs>
        <Typography vibe="text-reverse-intense" variant="subtitle2">
          {content}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

export const DisplayWithInfoFormPreview: FunctionComponent<PropsWithChildren<InfoFormProps>> = ({
  content,
  children,
}) => {
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();
  const { isPreviewTemplateMode } = useContext(FrontendFormContext);

  return (
    <ThemeProvider theme={zeffyTheme}>
      <Tooltip
        vibe="brand"
        title={!isPreviewTemplateMode || isSmallScreen ? null : <InfoForm content={content} />}
        placement="right"
      >
        <Grid container>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </Grid>
      </Tooltip>
    </ThemeProvider>
  );
};
