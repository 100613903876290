/* eslint-disable sonarjs/no-duplicate-string */

import { useContext } from "react";

import {
  FRAUDULENT_PAYMENT_INTENT,
  ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE,
  PaymentMethod,
} from "@simplyk/common";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { UseFormSetValue } from "react-hook-form";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { getBillingDetails } from "../../helpers/payment";
import { captureSentryError, captureSentryMessage } from "../../helpers/sentry";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";
import { useSubmitCommand } from "../PaymentProcessor/hooks/useSubmitCommand";
import { isPaymentFailed } from "../PaymentProcessor/type";

import { HandleErrorParams, unknownPaymentErrorCode } from "./helper";
import { HandlePostSubmit } from "./useStripePayment";

import { AmplitudeEvents } from "@/constants/amplitude";
import { PaymentMode } from "@/helpers/stripe";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";
import { TicketingPaymentInput } from "@/types/ticketing";

export const usePad = ({
  setValue,
  handleError,
  handlePostSubmit,
}: {
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  handleError: (params: HandleErrorParams) => void;
  handlePostSubmit: HandlePostSubmit;
}) => {
  const { handleSubmitCommand } = useSubmitCommand({ setValue });
  const { t } = useTranslate();
  const { organization, commandId, formType, category, setIsSubmitting } = useContext(FrontendFormContext);
  const stripe = useStripe();
  const { logAmplitudeEvent } = useAmplitude();

  const payByPad = async (submitCommandInput: SubmitCommandFormInput, paymentMode: PaymentMode) => {
    if (!stripe) {
      logAmplitudeEvent(AmplitudeEvents.FormClickSubmitPaymentError, {
        step: "stripe-load",
        commandId,
        code: "stripe_load_error",
        isUnknown: true,
        paymentMethod: PaymentMethod.Pad,
        formType,
        formCategory: category,
      });
      captureSentryError({ message: "PaymentError: Stripe Sdk not found" });
      return;
    }

    captureSentryMessage({
      message: "Start submit command",
      params: {
        commandId,
        formType,
        organizationId: organization?.id,
        category: category ? category.toString() : "null",
      },
    });

    const submitResponse = await handleSubmitCommand({ ...submitCommandInput, paymentMethod: PaymentMethod.Pad });

    if (submitResponse && isPaymentFailed(submitResponse)) {
      handleError({
        message:
          submitResponse.error.code === ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            ? ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            : submitResponse.error.message,
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: submitResponse.error.code,
          message: submitResponse.error.message,
          isUnknown: false,
          paymentMethod: PaymentMethod.Pad,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const clientSecret = submitResponse.data?.clientSecret;
    const command = submitResponse.data?.command;
    const { paymentInput, isExpressCheckout = false } = submitCommandInput;

    if (clientSecret === FRAUDULENT_PAYMENT_INTENT.clientSecret) {
      const result = await handlePostSubmit({ command, formType, paymentInput, isExpressCheckout });
      if (result.error || !result.returnUrl) {
        handleError({
          message: t("common", "unknown_error", { code: unknownPaymentErrorCode.pad1.code }),
          commandId: command?.id,
          metadata: {
            step: "post-submit",
            commandId,
            code: result.error ? "unknown_error" : "missing_return_url",
            message: result.error || t("common", "unknown_error", { code: unknownPaymentErrorCode.pad1.code }),
            isUnknown: true,
            paymentMethod: submitCommandInput.paymentInput.paymentMethod,
            isExpressCheckout: submitCommandInput.isExpressCheckout,
          },
        });

        return;
      }
      return result.returnUrl;
    }

    if (!clientSecret || !command) {
      handleError({
        message: t("common", "unknown_error", { code: unknownPaymentErrorCode.pad2.code }),
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: !clientSecret ? "missing_client_secret" : "missing_command",
          isUnknown: true,
          paymentMethod: PaymentMethod.Pad,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    let confirmError: StripeError | undefined;
    if (paymentMode === "setup") {
      const { error } = await stripe.confirmAcssDebitSetup(clientSecret, {
        payment_method: {
          billing_details: getBillingDetails(submitCommandInput.paymentInput, organization),
        },
        mandate_data: {
          acss_debit: {
            mandate_options: {
              payment_schedule: "interval",
              interval_description: "on completion of checkout",
              transaction_type: "personal",
            },
          },
        },
      });
      confirmError = error;
    } else {
      const { error } = await stripe.confirmAcssDebitPayment(clientSecret, {
        payment_method: {
          billing_details: getBillingDetails(submitCommandInput.paymentInput, organization),
        },
      });
      confirmError = error;
    }

    if (confirmError) {
      handleError({
        message: confirmError.message || t("common", "unknown_error", { code: unknownPaymentErrorCode.pad3.code }),
        commandId,
        metadata: {
          step: "manual-confirmation",
          commandId,
          code: confirmError.code,
          isUnknown: false,
          paymentMethod: PaymentMethod.Pad,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const result = await handlePostSubmit({ paymentInput, command, formType, isExpressCheckout });

    if (result.returnUrl) {
      setIsSubmitting(true);
      return result.returnUrl;
    }

    if (result.error) {
      handleError({
        message: result.error,
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "unknown_error",
          message: result.error,
          isUnknown: true,
          paymentMethod: PaymentMethod.Pad,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    } else {
      handleError({
        message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.pad4.code }),
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "missing_return_url",
          message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.pad4.code }),
          isUnknown: true,
          paymentMethod: PaymentMethod.Pad,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    }
  };

  return { payByPad };
};
