import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
  (theme) => ({
    tipInput: {
      width: "100%",
    },
    tipSelect: { ...theme.typography.body2, paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },
    percentageWhichAddMiddleContribution: {
      position: "relative",
      textAlign: "right",
    },
    percentageWhichAddMiddleContributionText: {
      width: "max-content",
      position: "absolute",
      top: 0,
      right: 0,
    },
    helperText: {
      color: theme.palette.text.form.quiet,
    },
  }),
  { index: 10 }
);
