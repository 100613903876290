import { FC, PropsWithChildren, useCallback } from "react";

import { Box, SxProps, Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { PaymentMethod } from "@simplyk/common";

import { Translate, useTranslate } from "../../hooks/useTranslate";

import StripeBank from "@/icons/external/stripe-bank.svg";
import StripeCard from "@/icons/external/stripe-card.svg";
import StripeCheque from "@/icons/external/stripe-cheque.svg";
import StripeRadioEmpty from "@/icons/external/stripe-radio-empty.svg";
import StripeRadioSelected from "@/icons/external/stripe-radio-selected.svg";

const getPaymentMethodLabel = (
  t: Translate,
  paymentMethod: PaymentMethod,
  offlinePaymentWording?: string | null,
  isPreviewPaymentWording?: boolean
) => {
  switch (paymentMethod) {
    case PaymentMethod.Card:
      if (isPreviewPaymentWording) {
        return t("common", "paymentMethod.previewWording.card");
      }
      return t("common", "paymentMethod.card");
    case PaymentMethod.Ach:
      return t("common", "paymentMethod.ach");
    case PaymentMethod.Pad:
      return t("common", "paymentMethod.pad");
    case PaymentMethod.Cheque:
      return t("common", "paymentMethod.cheque");
    case PaymentMethod.Cash:
      return offlinePaymentWording || t("common", "paymentMethod.cash");
    default:
      return "";
  }
};

const getPaymentMethodIcon = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod) {
    case PaymentMethod.Card:
      return <StripeCard />;
    case PaymentMethod.Ach:
      return <StripeBank />;
    case PaymentMethod.Pad:
      return <StripeBank />;
    case PaymentMethod.Cheque:
      return <StripeCheque />;
    case PaymentMethod.Cash:
      return <StripeCheque />;
    default:
      return <></>;
  }
};

interface PaymentMethodOptionProps extends PropsWithChildren {
  className?: string;
  isFirst: boolean;
  isLast: boolean;
  loading?: boolean;
  onSelect: (value: PaymentMethod) => void;
  selected: boolean;
  paymentMethod: PaymentMethod;
  sx?: SxProps<Theme>;
  "data-test"?: string;
  hasBorderBottom?: boolean;
  offlinePaymentWording?: string | null;
  isPreviewPaymentWording?: boolean;
}

export const PaymentMethodOption: FC<PaymentMethodOptionProps> = ({
  className,
  isFirst = false,
  isLast = false,
  loading = false,
  onSelect,
  selected,
  paymentMethod,
  "data-test": dataTest,
  children,
  hasBorderBottom = true,
  offlinePaymentWording,
  isPreviewPaymentWording,
}) => {
  const { t } = useTranslate();

  const handleClick = useCallback(() => {
    if (!selected) {
      onSelect(paymentMethod);
    }
  }, [onSelect, selected, paymentMethod]);

  if (loading) {
    return <Skeleton variant="rectangular" height={55} animation="wave" />;
  }

  return (
    <Box
      onClick={handleClick}
      className={className}
      sx={(theme) => ({
        padding: 2,
        color: theme.palette.text.brand.moderate,
        fill: theme.palette.text.brand.moderate,
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "16.1px",
        border: "1px solid",
        backgroundColor: theme.constants.isFormV2 ? theme.palette.surface.form.supershy : "transparent",
        borderColor: theme.constants.isFormV2 ? theme.palette.border.form.moderate : "#e6e6e6",
        minHeight: 55,
        borderTopLeftRadius: isFirst ? 5 : 0,
        borderTopRightRadius: isFirst ? 5 : 0,
        borderBottomLeftRadius: isLast ? 5 : 0,
        borderBottomRightRadius: isLast ? 5 : 0,
        borderBottom: hasBorderBottom ? undefined : "none",
        cursor: selected ? "unset" : "pointer",
        "&:hover": !selected && {
          color: theme.palette.text.brand.moderate,
          fill: theme.palette.text.brand.moderate,
        },
      })}
      data-test={`payment-method-${dataTest}`}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "1.2em",
            width: "1.2em",
            display: "flex",
            marginRight: 1.5,
          }}
        >
          {selected ? <StripeRadioSelected /> : <StripeRadioEmpty />}
        </Box>
        <Box
          sx={{
            height: "1.2em",
            width: "2.25em",
            display: "flex",
            marginRight: "10.5px",
            "& > svg": {
              width: "unset",
            },
          }}
        >
          {getPaymentMethodIcon(paymentMethod)}
        </Box>
        <Box>{getPaymentMethodLabel(t, paymentMethod, offlinePaymentWording, isPreviewPaymentWording)}</Box>
      </Box>
      {children}
    </Box>
  );
};
