/* eslint-disable sonarjs/no-duplicate-string */
import { useContext } from "react";

import {
  FRAUDULENT_PAYMENT_INTENT,
  FormType,
  ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE,
} from "@simplyk/common";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { inferRouterOutputs } from "@trpc/server";
import { UseFormSetValue } from "react-hook-form";
import { v4 } from "uuid";

import { isTest } from "../../constants/env";
import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { RecaptchaAction } from "../../enums/recaptcha";
import { useConfirmPaymentIntentMutation } from "../../gql/queries/generated/paymentQuery";
import { getBillingDetails } from "../../helpers/payment";
import { captureSentryMessage } from "../../helpers/sentry";
import { AppRouterType } from "../../helpers/trpc";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { SubmitCommandFormInput } from "../PaymentProcessor/helper";
import { useSubmitCommand } from "../PaymentProcessor/hooks/useSubmitCommand";
import { isPaymentFailed } from "../PaymentProcessor/type";

import { HandleErrorParams, unknownPaymentErrorCode } from "./helper";

import { TicketingPaymentInput } from "@/types/ticketing";

type SubmitCommandOutput = inferRouterOutputs<AppRouterType>["form_submitCommand"];

export type HandlePostSubmit = ({
  paymentInput,
  command,
  formType,
  isExpressCheckout = false,
}: {
  paymentInput: DonationFormPaymentInput | TicketingPaymentInput;
  command?: NonNullable<NonNullable<SubmitCommandOutput>["data"]>["command"];
  formType: FormType;
  isExpressCheckout?: boolean;
}) => Promise<{
  returnUrl?: string | null | undefined;
  error?: string | null | undefined;
}>;

export const useStripePayment = ({
  setValue,
  handleError,
  handlePostSubmit,
}: {
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  handleError: (params: HandleErrorParams) => void;
  handlePostSubmit: HandlePostSubmit;
}) => {
  const { organization, commandId, formType, category } = useContext(FrontendFormContext);
  const { handleSubmitCommand } = useSubmitCommand({ setValue });
  const { t } = useTranslate();
  const stripe = useStripe();
  const elements = useElements();
  const [confirmPaymentIntent] = useConfirmPaymentIntentMutation();
  const { execute } = useRecaptchaRetry();

  const payByStripe = async (submitCommandInput: SubmitCommandFormInput) => {
    if (!stripe || !elements) {
      handleError({
        message: t("common", "unknown_error", { code: unknownPaymentErrorCode.card1.code }),
        commandId,
        metadata: {
          step: "stripe-load",
          commandId,
          code: "stripe_load_error",
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    const res = await elements?.submit();

    if (res.error) {
      elements.getElement("payment")?.focus();
      handleError({
        message: res.error.message,
        commandId,
        metadata: {
          step: "validate-stripe-element",
          commandId,
          code: res.error.code,
          error: res.error.message,
          isUnknown: false,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });
      return;
    }

    const billing_details = getBillingDetails(submitCommandInput.paymentInput, organization);

    let paymentMethod: StripePaymentMethod | null = null;
    if (isTest) {
      paymentMethod = {
        id: `pm_test_${v4()}`,
      } as StripePaymentMethod;
    } else {
      // Create the PaymentMethod using the details collected by the Payment Element
      const { error, paymentMethod: createdPaymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details,
        },
      });

      if (error) {
        handleError({
          message: error.message,
          commandId,
          metadata: {
            step: "create-payment-method",
            commandId,
            code: error.code,
            error: error.message,
            isUnknown: false,
            paymentMethod: submitCommandInput.paymentInput.paymentMethod,
            isExpressCheckout: submitCommandInput.isExpressCheckout,
          },
        });

        return;
      }
      paymentMethod = createdPaymentMethod;
    }
    captureSentryMessage({
      message: "Start submit command",
      params: {
        commandId,
        formType,
        organizationId: organization?.id,
        category: category ? category.toString() : "null",
      },
    });

    const submitResponse = await handleSubmitCommand({
      ...submitCommandInput,
      stripePaymentMethodId: paymentMethod.id,
    });

    if (submitResponse && isPaymentFailed(submitResponse)) {
      handleError({
        message:
          submitResponse.error.code === ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            ? ORGANIZATION_CANNOT_ACCEPT_PAYMENT_WITHOUT_BANK_ERROR_CODE
            : submitResponse.error.message,
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: submitResponse.error.code,
          error: submitResponse.error.message,
          isUnknown: false,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });
      return;
    }

    const clientSecret = submitResponse.data?.clientSecret;
    const command = submitResponse.data?.command;
    const { paymentInput, isExpressCheckout } = submitCommandInput;

    if (clientSecret === FRAUDULENT_PAYMENT_INTENT.clientSecret) {
      const result = await handlePostSubmit({ command, formType, paymentInput, isExpressCheckout });
      if (result.error || !result.returnUrl) {
        handleError({
          message: t("common", "unknown_error", { code: unknownPaymentErrorCode.card2.code }),
          commandId: command?.id,
          metadata: {
            step: "post-submit",
            commandId,
            code: result.error ? "unknown_error" : "missing_return_url",
            message: result.error || t("common", "unknown_error", { code: unknownPaymentErrorCode.card2.code }),
            isUnknown: true,
            paymentMethod: submitCommandInput.paymentInput.paymentMethod,
            isExpressCheckout: submitCommandInput.isExpressCheckout,
          },
        });

        return;
      }
      return result.returnUrl;
    }

    if (!command) {
      handleError({
        message: t("common", "unknown_error", { code: unknownPaymentErrorCode.card3.code }),
        commandId,
        metadata: {
          step: "server-submit",
          commandId,
          code: "missing_command",
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
      });

      return;
    }

    if (clientSecret && !isTest) {
      try {
        const { error, paymentIntent, setupIntent } = await stripe.handleNextAction({ clientSecret });
        if (error) {
          handleError({
            message: error.message,
            commandId: command.id,
            metadata: {
              step: "handle-next-action",
              commandId,
              code: error.code,
              message: error.message,
              isUnknown: false,
              paymentMethod: submitCommandInput.paymentInput.paymentMethod,
              isExpressCheckout: submitCommandInput.isExpressCheckout,
            },
          });

          return;
        }

        if (!paymentIntent && !setupIntent) {
          handleError({
            message: t("common", "unknown_error", { code: unknownPaymentErrorCode.card4.code }),
            commandId: command.id,
            metadata: {
              step: "handle-next-action",
              commandId,
              code: "missing_intent",
              isUnknown: true,
              paymentMethod: submitCommandInput.paymentInput.paymentMethod,
              isExpressCheckout: submitCommandInput.isExpressCheckout,
            },
          });

          return;
        }

        if (paymentIntent && paymentIntent.status === "requires_payment_method") {
          handleError({
            message:
              paymentIntent.last_payment_error?.message ||
              t("common", "unknown_error", { code: unknownPaymentErrorCode.card5.code }),
            commandId: command.id,
            metadata: {
              step: "handle-next-action",
              commandId,
              paymentMethod: submitCommandInput.paymentInput.paymentMethod,
              isExpressCheckout: submitCommandInput.isExpressCheckout,
              code: paymentIntent.last_payment_error?.code,
              message: paymentIntent.last_payment_error?.message,
              isUnknown: false,
              status: paymentIntent.status,
            },
          });

          return;
        }

        if (paymentIntent && paymentIntent.status === "requires_confirmation") {
          const action = RecaptchaAction.ConfirmPaymentIntent;
          const email = command.email;
          const { data } = await execute(action, email, confirmPaymentIntent, {
            variables: { confirmPaymentIntentInput: { paymentIntentId: paymentIntent.id } },
          });

          if (data?.confirmPaymentIntent.error) {
            const code = data.confirmPaymentIntent.error.code;
            const displayedMessage =
              code && code === "confirm_payment_intent_failed"
                ? data.confirmPaymentIntent.error?.message ||
                  t("common", "unknown_error", { code: unknownPaymentErrorCode.card6.code })
                : t("common", "unknown_error", { code: unknownPaymentErrorCode.card6.code });
            handleError({
              message: displayedMessage,
              commandId: command.id,
              metadata: {
                step: "manual-confirmation",
                commandId,
                code,
                message: displayedMessage,
                isUnknown: displayedMessage === t("common", "unknown_error"),
                paymentMethod: submitCommandInput.paymentInput.paymentMethod,
                isExpressCheckout: submitCommandInput.isExpressCheckout,
                status: paymentIntent.status,
              },
            });

            return;
          }
        }
      } catch (error) {
        handleError({
          message: t("common", "unknown_error", { code: unknownPaymentErrorCode.card7.code }),
          commandId: command.id,
          metadata: {
            commandId,
            code: "caught_exception",
            message: (error as Error).message,
            isUnknown: true,
            paymentMethod: submitCommandInput.paymentInput.paymentMethod,
            isExpressCheckout: submitCommandInput.isExpressCheckout,
          },
        });

        return;
      }
    }

    const result = await handlePostSubmit({ paymentInput, command, formType, isExpressCheckout });

    if (result.returnUrl) {
      return result.returnUrl;
    }

    if (result.error) {
      handleError({
        message: result.error,
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "unknown_error",
          message: result.error,
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    } else {
      handleError({
        message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.card8.code }),
        commandId: command.id,
        metadata: {
          step: "post-submit",
          commandId,
          code: "missing_return_url",
          message: t("common", "unknown_error.paymentSucceeded", { code: unknownPaymentErrorCode.card8.code }),
          isUnknown: true,
          paymentMethod: submitCommandInput.paymentInput.paymentMethod,
          isExpressCheckout: submitCommandInput.isExpressCheckout,
        },
        isPaymentSucceeded: true,
      });
    }
  };

  return { payByStripe };
};
