import { useState, useCallback, useContext } from "react";

import { AmplitudeEvents } from "../../../constants/amplitude";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { useAmplitude } from "../../../hooks/amplitude/useAmplitude";

export const useZeffyDescription = () => {
  const { logAmplitudeEvent } = useAmplitude();
  const { formType, category } = useContext(FrontendFormContext);
  const [showZeffyDescription, setOpenZeffyDescription] = useState(false);
  const openZeffyDescription = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.FormClickTipKnowMore, {
      formType,
      formCategory: category,
    });
    setOpenZeffyDescription(true);
  }, [category, formType, logAmplitudeEvent]);
  const closeZeffyDescription = useCallback(() => {
    setOpenZeffyDescription(false);
  }, []);
  return {
    showZeffyDescription,
    openZeffyDescription,
    closeZeffyDescription,
  };
};
