export const UTM_SOURCE_PARAM_KEY = "utm_source";

export const ThankYouLearnMoreParams = "thankyou_learnmore";
export const ThankYouPoweredByZeffyParams = "thankyou_poweredbyzeffy";

export const FreeFormsTicketingDesktop = "freeforms_ticketing_desktop";
export const FreeFormsDonationDesktop = "freeforms_donation_desktop";
export const FreeFormsFundraisingDesktop = "freeforms_fundraising_desktop";

export const FreeFormsTicketingMobile = "freeforms_ticketing_mobile";
export const FreeFormsDonationMobile = "freeforms_donation_mobile";
export const FreeFormsFundraisingMobile = "freeforms_fundraising_mobile";

export const FreeFormsTicketingEmbed = "freeforms_ticketing_embed";
export const FreeFormsDonationEmbed = "freeforms_donation_embed";
