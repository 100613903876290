import { FC } from "react";

import { Box } from "@mui/material";
import { OrganizationCountry, PaymentMethod } from "@simplyk/common";

import { PaymentMethodOption } from "./PaymentMethodOption";

interface PreviewPaymentMethodsProps {
  country: OrganizationCountry;
}

export const PreviewPaymentMethods: FC<PreviewPaymentMethodsProps> = ({ country }) => {
  const paymentMethods = [
    PaymentMethod.Card,
    ...(country === OrganizationCountry.Canada ? [PaymentMethod.Pad] : [PaymentMethod.Ach]),
    PaymentMethod.Cheque,
  ];

  return (
    <Box>
      {paymentMethods.map((method, index) => {
        const isLast = index === paymentMethods.length - 1;
        return (
          <PaymentMethodOption
            key={method}
            paymentMethod={method}
            isFirst={index === 0}
            isLast={isLast}
            hasBorderBottom={isLast} // Last payment method has no border bottom
            onSelect={() => {}}
            selected={false}
            loading={false}
            isPreviewPaymentWording
          />
        );
      })}
    </Box>
  );
};
