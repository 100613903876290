import { Undefinable, Emptyable, PaymentMethod, CampaignAction } from "@simplyk/common";
import { inferRouterOutputs } from "@trpc/server";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

import { DiscountObject } from "../../gql/gql-types";
import { AppRouterType } from "../../helpers/trpc";
import { DonationFormPaymentInput } from "../../types/donationForm";

import { ScreenSize } from "@/hooks/useScreenSize";
import { TicketingPaymentInput } from "@/types/ticketing";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

type SubmitCommandOutput = NonNullable<inferRouterOutputs<AppRouterType>["form_submitCommand"]>["data"];

export interface PaymentSucceeded {
  data: SubmitCommandOutput;
  paymentInput: DonationFormPaymentInput | TicketingPaymentInput;
  onSuccess?: (() => Promise<void>) | undefined;
}

export type Event = {
  title: string;
  address: string;
  startTime: number | undefined;
  endTime: number | undefined;
};

export interface AmplitudeContext extends Record<string, unknown> {
  paymentMethod: Undefinable<PaymentMethod>;
  isMobile: boolean;
  isEmbed: boolean;
  screenSize: ScreenSize;
  hasReceiptMessage?: boolean | undefined;
  hasNoSuggestedAmount?: boolean | undefined;
}

export type SaveDonationOrOrderObject = PaymentSucceeded | PaymentFailed;

export interface PaymentFailed {
  error: { message: string; code: string };
}

export interface CreatedCampaigns {
  id: string;
  teamId?: string;
  color: string;
  logoUrl?: string;
  campaignAction: CampaignAction;
  multipleCampaigns: boolean;
  isFundraiserFormEditDisabled: boolean;
  token: string;
}

export const isPaymentFailed = (result: SaveDonationOrOrderObject): result is PaymentFailed => {
  return Boolean((result as PaymentFailed).error);
};

export type PaymentElementProps<T extends FieldValues> = {
  handleSubmit: UseFormHandleSubmit<TicketingPaymentInput | DonationFormPaymentInput>;
  formObject: DonationFormOutput | TicketingOutput;
  setValue: UseFormSetValue<DonationFormPaymentInput | TicketingPaymentInput>;
  trigger: UseFormTrigger<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  validDiscount?: Emptyable<DiscountObject>;
  billingDetails?: React.ReactNode;
  shouldDisplaySubmitButton?: boolean;
};

export interface CustomRedirect {
  url: string;
  queryParams?: Record<string, unknown>;
}
