import { useMemo, MouseEvent } from "react";

import { useTheme } from "@mui/material/styles";

import { DEFAULT_CONTRAST_COLOR } from "../../theme/palette";

export interface GiveWithHeartButtonProps {
  onSubmit: (event?: MouseEvent<HTMLButtonElement>) => Promise<void>;
  cardIsFilled: boolean;
  isCardPaymentMethod: boolean;
  paymentInProgress: boolean;
  disabled?: boolean;
  showProceedMessage?: boolean;
  wording: string;
}

export const useGiveHeartButton = ({
  cardIsFilled,
  isCardPaymentMethod,
  paymentInProgress,
}: GiveWithHeartButtonProps) => {
  const theme = useTheme();

  const heartUrl = useMemo(() => {
    const isLight = theme.palette.primary.contrastText === DEFAULT_CONTRAST_COLOR;
    const isSpinning = paymentInProgress;
    const isDisabled = !isLight && !cardIsFilled && isCardPaymentMethod;

    return `/images/heart-${isDisabled ? "disabled-" : ""}${isLight ? "light-" : ""}${
      isSpinning ? "spinning" : "static"
    }.svg`;
  }, [cardIsFilled, isCardPaymentMethod, paymentInProgress, theme.palette.primary.contrastText]);

  return { heartUrl };
};
