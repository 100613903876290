import { FC, useContext } from "react";

import { Grid, useTheme } from "@mui/material";
import {
  StripeRecurringInterval,
  formatCurrencyAmount,
  getDollarValue,
  formatDateToMedDate,
  getDatePlusNMonths,
  getDatePlusNYears,
  FormType,
} from "@simplyk/common";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { FrontendTicketingContext, MembershipRenewalBuyerInfo } from "../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useTranslate } from "../../hooks/useTranslate";
import { Typography } from "../design-system/Typography";

export const PaymentProcessorTotalWithTip: FC = () => {
  const theme = useTheme();
  const { membershipRenewalBuyerInfo } = useContext(FrontendTicketingContext);
  const { displayedFormAmount, tip, stripeRecurrenceInterval, currency, formType } = useContext(FrontendFormContext);

  const { isoLocale } = useLocaleContext();
  const { t } = useTranslate();
  const amount = getDollarValue(displayedFormAmount + tip);

  const isFormV2 = theme.constants.isFormV2;

  const renewalDate = getRenewalDate(membershipRenewalBuyerInfo, stripeRecurrenceInterval, formType);
  const formattedRenewalDate = renewalDate ? formatDateToMedDate(renewalDate, isoLocale) : undefined;

  return (
    <Grid
      item
      xs={12}
      sx={{
        borderTop: `1px solid ${isFormV2 ? theme.palette.border.form.moderate : theme.palette.neutral[90]}`,
        backgroundColor: isFormV2 ? theme.palette.surface.form.intense : theme.palette.grey[100],
        padding: theme.spacing(2),
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: isFormV2 ? theme.palette.text.form.intense : undefined,
            }}
          >
            {t("donationForm", "total")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body1"
            align="right"
            component="div"
            data-test="payment-processor-total-with-tip-amount"
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: isFormV2 ? theme.palette.text.form.intense : undefined,
            }}
          >
            {currency && formatCurrencyAmount(amount, isoLocale, currency)}
            {stripeRecurrenceInterval &&
              ` / ${
                stripeRecurrenceInterval === StripeRecurringInterval.year
                  ? t("dashboard", "common.year")
                  : t("dashboard", "common.month")
              }`}
          </Typography>
          {stripeRecurrenceInterval && formattedRenewalDate && (
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Typography
                  vibe="text-form-supershy"
                  variant="body2"
                  sx={{
                    color: theme.palette.text.secondary,
                    textAlign: "right",
                  }}
                  data-test="payment-processor-total-with-tip-renew-date"
                >
                  {t("ticketing", "payment.total.recurring", { date: formattedRenewalDate })}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const getRenewalDate = (
  membershipRenewalBuyerInfo: MembershipRenewalBuyerInfo | undefined,
  stripeRecurrenceInterval: StripeRecurringInterval | null | undefined,
  formType: FormType
): Date | null => {
  if (membershipRenewalBuyerInfo?.expirationDate) {
    return new Date(membershipRenewalBuyerInfo.expirationDate);
  }

  if (formType === FormType.DonationForm && stripeRecurrenceInterval) {
    switch (stripeRecurrenceInterval) {
      case StripeRecurringInterval.month:
        return getDatePlusNMonths(new Date(), 1);
      case StripeRecurringInterval.year:
        return getDatePlusNYears(new Date(), 1);
      default:
        return null;
    }
  }

  return null;
};
