import { useContext, useState, useCallback, useEffect, ChangeEvent, ReactElement, memo } from "react";

import { Box, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  DiscountType,
  IsoLocale,
  formatCurrencyAmount,
  getCurrencyFromCountry,
  getDollarValue,
  Nullable,
  Emptyable,
  OrganizationCountry,
} from "@simplyk/common";
import classnames from "classnames";
import { FieldValues, Path, UseFormRegister, UseFormSetValue } from "react-hook-form";

import { AmplitudeEvents } from "../../constants/amplitude";
import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { usePreviewContext } from "../../features/LiveFormEditor/LivePreview/context/PreviewContext";
import { DiscountObject, TicketingFormCategory } from "../../gql/gql-types";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { Button } from "@/design-system/Button";
import { Icon } from "@/design-system/Icon/Icon";
import { TextField } from "@/design-system/TextField/TextField";
import { Success as CheckCircle } from "@/icons/outlined";
import { useGetDiscountCodeFromTicketingIdAndBodyMutation } from "@/src/gql/queries/generated/discountQuery";

const PREFIX = "DiscountCodeField";

const classes = {
  discountCodeField: `${PREFIX}-discountCodeField`,
  validDiscountCode: `${PREFIX}-validDiscountCode`,
  validDiscountCodeLabel: `${PREFIX}-validDiscountCodeLabel`,
  validDiscountCodeIcon: `${PREFIX}-validDiscountCodeIcon`,
  fixedHeight: `${PREFIX}-fixedHeight`,
  checkDiscountCode: `${PREFIX}-checkDiscountCode`,
};

// TODO jss-to-styled
const StyledGrid = memo(
  styled(Grid)(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.discountCodeField}`]: {
      paddingRight: theme.spacing(1),
    },

    [`&& .${classes.validDiscountCode}`]: {
      backgroundColor: theme.palette.success.lightest,
      borderRadius: theme.radius(0.5),
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },

    [`&& .${classes.validDiscountCodeLabel}`]: {
      marginRight: theme.spacing(0.5),
    },

    [`&& .${classes.validDiscountCodeIcon}`]: {
      marginRight: theme.spacing(1),
    },

    [`&& .${classes.fixedHeight}`]: {
      minHeight: 45,
      display: "flex",
      alignItems: "center",
    },

    [`&& .${classes.checkDiscountCode}`]: {
      color: "black",

      ...(theme.constants.isFormV2 && {
        borderColor: theme.palette.border.form.moderate,
        color: theme.palette.text.form.moderate,
      }),

      "&:hover": {
        color: "black",
      },
    },
  }))
);

interface DiscountCodeFieldProps<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  discountIdName: Path<T>;
  ticketingId: string;
  organizationCountry: OrganizationCountry;
  discount?: DiscountObject;
  selectedOccurrenceId: Nullable<string>;
  formCategory?: Nullable<TicketingFormCategory>;
}

enum DiscountValidation {
  "Valid" = "Valid",
  "Invalid" = "Invalid",
  "UnCheck" = "UnCheck",
}

export function DiscountCodeField<T extends FieldValues>({
  setValue,
  register,
  discountIdName,
  ticketingId,
  organizationCountry,
  discount,
  selectedOccurrenceId,
  formCategory,
}: React.PropsWithChildren<DiscountCodeFieldProps<T>>): ReactElement<
  React.PropsWithChildren<DiscountCodeFieldProps<T>>
> {
  const { t } = useTranslate();
  const theme = useTheme();
  const isFormV2 = theme.constants.isFormV2;
  const { isPreview } = usePreviewContext();

  const { isoLocale } = useLocaleContext();
  const { logAmplitudeEvent } = useAmplitude();
  const [codeIsValid, setCodeIsValid] = useState<DiscountValidation>(DiscountValidation.UnCheck);
  const [codeIsLoading, setCodeIsLoading] = useState(false);
  const { validDiscount, setValidDiscount } = useContext(FrontendTicketingContext);
  const [execCheckDiscountCodeConformity] = useGetDiscountCodeFromTicketingIdAndBodyMutation();
  const [discountCodeBody, setDiscountCodeBody] = useState<string>(discount?.body || "");
  const currentDiscount = discount || validDiscount;
  const { isPhoneScreen } = useMediaQuery();

  const validateCode = useCallback(async () => {
    setCodeIsLoading(true);
    logAmplitudeEvent(AmplitudeEvents.DonorFormDiscountCodeChecked, {
      formId: ticketingId,
      formType: formCategory,
      organizationCountry,
    });

    const { data: discountCodeValidated } = await execCheckDiscountCodeConformity({
      variables: {
        ticketingId,
        discountCodeBody,
        occurrenceId: selectedOccurrenceId,
      },
    });

    const discountCode = discountCodeValidated?.getDiscountCodeFromTicketingIdAndBody.object as DiscountObject | null;
    if (!discountCode) {
      setCodeIsValid(DiscountValidation.Invalid);
    } else {
      const remainingUses = discountCode.remainingUses;
      if (remainingUses === null || (remainingUses && remainingUses > 0)) {
        setCodeIsValid(DiscountValidation.Valid);
        setValidDiscount(discountCode);
        setValue(discountIdName, discountCode.id as never);
      } else {
        setCodeIsValid(DiscountValidation.Invalid);
      }
    }
    setCodeIsLoading(false);
  }, [
    logAmplitudeEvent,
    ticketingId,
    formCategory,
    organizationCountry,
    execCheckDiscountCodeConformity,
    discountCodeBody,
    selectedOccurrenceId,
    setValidDiscount,
    setValue,
    discountIdName,
  ]);

  useEffect(() => {
    if (discountCodeBody) {
      setCodeIsValid(DiscountValidation.UnCheck);
    }
  }, [discountCodeBody]);

  useEffect(() => {
    if (selectedOccurrenceId) {
      setCodeIsValid(DiscountValidation.UnCheck);
      setValidDiscount(null);
      setValue(discountIdName, undefined as never);
      setDiscountCodeBody("");
    }
  }, [selectedOccurrenceId, setCodeIsValid, setValidDiscount, setValue, setDiscountCodeBody, discountIdName]);

  const inputRequiredValidation = discountCodeBody && codeIsValid !== DiscountValidation.Valid;

  const deleteDiscountCode = useCallback(() => {
    setCodeIsValid(DiscountValidation.UnCheck);
    setValidDiscount(null);
    setValue(discountIdName, undefined as never);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDiscountCodeBodyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDiscountCodeBody(event.target.value),
    []
  );

  if (codeIsValid === DiscountValidation.Valid) {
    if (isFormV2) {
      return (
        <Box
          sx={{
            paddingX: 2,
            paddingY: 2,
            width: "100%",
            border: `1px solid ${theme.palette.border.form.moderate}`,
            borderRadius: 2,
            marginBottom: 2,
          }}
        >
          <StyledGrid
            container
            className={classnames(classes.validDiscountCode, classes.fixedHeight)}
            justifyContent="space-between"
          >
            <input {...register(discountIdName)} style={{ display: "none" }} />
            <Grid item alignSelf="center">
              <Grid container>
                <Grid item marginTop={isPhoneScreen ? 0 : "3px"}>
                  <Icon vibe="inherit" className={classes.validDiscountCodeIcon}>
                    <CheckCircle />
                  </Icon>
                </Grid>
                <Grid item direction="column" alignSelf="center">
                  <Grid container direction={isPhoneScreen ? "column" : "row"}>
                    <Grid item>
                      <Typography variant="subtitle2" marginRight={isPhoneScreen ? 0 : 1}>
                        {t("ticketing", "discountCodeApplied")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" marginRight={isPhoneScreen ? 0 : 0.5}>
                        {currentDiscount?.body}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {t("ticketing", "validDiscountCode.discount", {
                          value: getValidDiscountLabel(
                            currentDiscount,
                            organizationCountry,
                            isoLocale,
                            t("ticketing", "discountCodeField.valid.defaultLabel")
                          ),
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="center">
              <Button
                vibe="brand"
                size="small"
                variant="outlined"
                onClick={deleteDiscountCode}
                sx={(theme) => ({
                  borderColor: `${theme.palette.border.form.moderate} !important`,
                  color: `${theme.palette.text.form.moderate} !important`,
                  fill: `${theme.palette.text.form.moderate} !important`,
                })}
              >
                {t("common", "remove")}
              </Button>
            </Grid>
          </StyledGrid>
        </Box>
      );
    }
    return (
      <StyledGrid
        container
        className={classnames(classes.validDiscountCode, classes.fixedHeight)}
        alignItems="center"
        justifyContent="space-between"
      >
        <input {...register(discountIdName)} style={{ display: "none" }} />
        <Grid item>
          <Grid container>
            <Icon vibe="inherit" className={classes.validDiscountCodeIcon}>
              <CheckCircle />
            </Icon>
            <Typography variant="subtitle2" className={classes.validDiscountCodeLabel}>
              {t("ticketing", "validDiscountCode")}
            </Typography>
            <Typography variant="body2">
              {t("ticketing", "validDiscountCode.body", {
                body: currentDiscount?.body,
                value: getValidDiscountLabel(
                  currentDiscount,
                  organizationCountry,
                  isoLocale,
                  t("ticketing", "discountCodeField.valid.defaultLabel")
                ),
              })}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button vibe="positive" size="small" variant="text" onClick={deleteDiscountCode}>
            {t("common", "delete")}
          </Button>
        </Grid>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid container className={classes.fixedHeight} spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="flex-end">
          <input {...register(discountIdName)} data-test="discount-code-input" style={{ display: "none" }} />
          <Grid item xs className={classes.discountCodeField}>
            <TextField
              value={discountCodeBody}
              onChange={onDiscountCodeBodyChange}
              error={Boolean(inputRequiredValidation && codeIsValid !== DiscountValidation.UnCheck)}
              placeholder={!isPreview ? t("common", "discountCodeField.input.placeholder") : ""}
              label={isFormV2 ? t("ticketing", "discountCode") : ""}
              disableInjectingGlobalStyles={isPreview}
              id="discount-code-field"
            />
          </Grid>
          {codeIsValid === DiscountValidation.UnCheck && (
            <Grid item>
              <Button
                vibe="neutral"
                variant="outlined"
                onClick={validateCode}
                fullWidth
                disabled={!discountCodeBody}
                isLoading={codeIsLoading}
                data-test="discount-code-validate-button"
                className={classes.checkDiscountCode}
              >
                {t("ticketing", "validateDiscountCode")}
              </Button>
            </Grid>
          )}
          {codeIsValid === DiscountValidation.Invalid && (
            <Grid item xs={2}>
              <Button
                variant="outlined"
                vibe="danger"
                fullWidth
                onClick={validateCode}
                disabled
                isLoading={codeIsLoading}
                data-test="discount-code-validate-button"
              >
                {t("ticketing", "invalid")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      {codeIsValid === DiscountValidation.Invalid && isFormV2 && (
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: theme.palette.text.danger.quiet }}>
            {t("ticketing", "invalidDiscountCode")}
          </Typography>
        </Grid>
      )}
    </StyledGrid>
  );
}

const getValidDiscountLabel = (
  discount: Emptyable<DiscountObject>,
  country: OrganizationCountry,
  locale: IsoLocale,
  defaultMessage: string
): string => {
  if (!discount) {
    return defaultMessage;
  }
  return discount?.type === DiscountType.Dollars
    ? formatCurrencyAmount(getDollarValue(discount.amount), locale, getCurrencyFromCountry(country))
    : `${discount?.amount}%`;
};
