import { ReactElement, FunctionComponent, useContext, memo } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FormType } from "@simplyk/common";

import { FrontendFormContext } from "../../contexts/FrontendFormContext";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { BubbleTooltip } from "../BubbleTooltip/BubbleTooltip";

import { Icon } from "@/design-system/Icon";
import { ArrowRight } from "@/icons/outlined";

const PREFIX = "ConfirmPaymentBubbleTooltip";

const classes = {
  tooltipRoot: `${PREFIX}-tooltipRoot`,
  whiteIcon: `${PREFIX}-whiteIcon`,
  link: `${PREFIX}-link`,
};

const StyledBubbleTooltip = memo(
  styled(BubbleTooltip)(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.tooltipRoot}`]: {
      backgroundColor: "rgba(0,0,0,0)",
      display: "flex",
      maxWidth: "unset",
      marginBottom: theme.spacing(5.5),
    },

    [`&& .${classes.whiteIcon}`]: {
      fill: "white",
      marginTop: theme.spacing(0.5),
    },

    [`&& .${classes.link}`]: {
      color: "white",
      textDecoration: "none",
      cursor: "pointer",
    },
  }))
);

interface ConfirmPaymentBubbleTooltipProps {
  children: ReactElement<unknown>;
  hidden?: boolean;
  forceOpen?: boolean;
  forceClose?: () => void;
  offset?: number;
}

export const ConfirmPaymentBubbleTooltip: FunctionComponent<
  React.PropsWithChildren<ConfirmPaymentBubbleTooltipProps>
> = ({ children, hidden, forceOpen, forceClose, offset }) => {
  const { isSmallScreen } = useMediaQuery();

  const { t } = useTranslate();
  const { formType, isOnboarding } = useContext(FrontendFormContext);

  if (!isOnboarding) {
    return children;
  }

  return (
    <StyledBubbleTooltip
      hidden={hidden}
      forceOpen={isSmallScreen ? forceOpen : undefined}
      placement={isSmallScreen ? "top" : "right"}
      forceClose={forceClose}
      offsetBottom={offset}
      vibe="positive"
      title={
        <Grid container spacing={1}>
          {!isSmallScreen && (
            <Grid item>
              <Icon size="small" className={classes.whiteIcon}>
                <ArrowRight />
              </Icon>
            </Grid>
          )}
          <Grid item xs>
            {!isSmallScreen && (
              <>
                <Typography variant="subtitle1">
                  {formType === FormType.Ticketing
                    ? t("common", "youWillGetCover1Ticketing")
                    : t("common", "youWillGetCover1Donation")}
                </Typography>
                <Typography variant="subtitle1">{t("common", "youWillGetCover2")}</Typography>
              </>
            )}
            {isSmallScreen && (
              <Typography variant="subtitle1">
                {formType === FormType.Ticketing
                  ? t("common", "youWillGetCover1TicketingSmallScreen")
                  : t("common", "youWillGetCover1DonationSmallScreen")}
              </Typography>
            )}
          </Grid>
        </Grid>
      }
    >
      {children}
    </StyledBubbleTooltip>
  );
};
